import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { AccessRole } from '../../../../models/enums'
import { LegalFeesDistributionObject } from '../../../../models/legalFeesDistributionObject'
import { getText } from '../../../../services/textFunctions'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../states/UserState'

import LegalFeesRangeSlider from './LegalFeesRangeSlider'
import { WhiteTooltip } from '../../../tooltip/TooltipComponent'
import { formattedNumToString } from '../../../../services/formatNum'
import { roundNumberTo } from '../../../../services/commonFunctions'
import { findSegmentAmount } from '../../../../services/disputedAmountFunctions'

type Props = {
  singleSegmentIndex: number
  changeEffect: boolean
  feeDistributions: LegalFeesDistributionObject[]
  setChangeEffect: (param1: boolean) => void
  pdfPreview?: boolean
  isFirstLoad?: boolean
  setIsFirstLoad?: (param: boolean) => void
  ownRole?: AccessRole
  totalCounterClaimedAmount: number
  disputedAmount: number
  absoluteSegmentHeight?: { segmentIndex: number; top: number; height: number }
}

export default function NewLegalFeesRange(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]
  const [roundingNumber, setRoundingNumber] = useState<number>(2) // a number that defines the number of decimals, depending on the currency settings -- number 100 or 1

  const [rangeValue, setRangeValue] = useState<number>(0) // the state that holds the value of the range slider -- integer from -10 to 10
  const [tempRangeValue, setTempRangeValue] = useState<number>(0) // the state that holds the value of the range slider -- integer from -10 to 10
  const [legalFeesRangeText, setlegalFeesRangeText] = useState<JSX.Element>() // the state that holds the text next to the slider -- str

  useEffect(() => {
    let nonMillionCurrnecy = currentSnapshot.currency.length === 3

    if (nonMillionCurrnecy) {
      setRoundingNumber(3)
    }

    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    setRangeValue(props.feeDistributions[props.singleSegmentIndex].value)
    setTempRangeValue(props.feeDistributions[props.singleSegmentIndex].value)
    // eslint-disable-next-line
  }, [props.feeDistributions])

  useEffect(() => {
    findLegalFeesRangeText(
      props.feeDistributions[props.singleSegmentIndex].value,
    )
    // eslint-disable-next-line
  }, [props.feeDistributions, props.disputedAmount, user.settings.language])

  const getSegmentAmount = (bottomAmount?: boolean) => {
    return roundNumberTo(
      findSegmentAmount(
        props.disputedAmount,
        props.totalCounterClaimedAmount,
        props.feeDistributions[props.singleSegmentIndex].range[
          bottomAmount ? 0 : 1
        ],
      ),
      roundingNumber,
    )
  }

  const isAmountIncluded = (bottomAmount?: boolean) => {
    if (bottomAmount) {
      return (
        props.singleSegmentIndex !== 1 || !currentSnapshot.includeBottomLFDLimit
      )
    }

    return (
      props.singleSegmentIndex === props.feeDistributions.length - 2 &&
      !currentSnapshot.includeTopLFDLimit
    )
  }

  function findLegalFeesRangeText(rangeValue: number) {
    setTempRangeValue(rangeValue)

    const topAmountText = `${formattedNumToString(
      Math.abs(getSegmentAmount()),
      user.settings,
    )} ${currentSnapshot.currency}`

    const bottomAmountText = `${formattedNumToString(
      Math.abs(getSegmentAmount(true)),
      user.settings,
    )} ${currentSnapshot.currency}`

    const textPart1 = `${
      rangeValue !== 0 ? Math.abs(rangeValue) * 10 : ''
    }${getText(
      rangeValue > 0
        ? 'description-30'
        : rangeValue < 0
        ? 'description-31'
        : 'description-32',
      user.settings,
      partiesFormat,
    )} `

    const textPart3 = `${getText('description-206a', user.settings)} ${
      isAmountIncluded()
        ? getText('description-170', user.settings)
        : getText('description-171', user.settings)
    } ${topAmountText} ${getText('description-206b', user.settings)} ${
      getSegmentAmount() * getSegmentAmount(true) < 0
        ? `${getText('description-239', user.settings, partiesFormat)} ${
            isAmountIncluded(true)
              ? getText('description-170', user.settings)
              : getText('description-171', user.settings)
          } ${bottomAmountText}`
        : `${
            isAmountIncluded(true)
              ? getText('description-170', user.settings)
              : getText('description-171', user.settings)
          } ${bottomAmountText}`
    }`

    let textPart2 = `${
      getSegmentAmount() >= 0
        ? getText('description-240', user.settings, partiesFormat)
        : getText('description-241', user.settings, partiesFormat)
    } ${
      props.feeDistributions[props.singleSegmentIndex].range[0] ===
      props.feeDistributions[props.singleSegmentIndex].range[1]
        ? `${getText('description-242', user.settings)} ${topAmountText}`
        : textPart3
    }.`

    setlegalFeesRangeText(
      <p data-openreplay-obscured>{textPart1 + textPart2}</p>,
    )
  }

  return (
    <>
      <div className="legalFeesRangeContainer" data-openreplay-obscured>
        <div
          className={
            props.pdfPreview
              ? 'legalFeesRangeTextContainer pdfPreviewlegalFeesRangeTextContainer'
              : 'legalFeesRangeTextContainer'
          }
          data-openreplay-obscured
        >
          <WhiteTooltip
            textAttribute={undefined}
            id={`legalFeesRangeTextTooltip-${props.singleSegmentIndex}`}
            title={legalFeesRangeText}
          >
            <p
              className={`legalFeesRangeText ${
                tempRangeValue > 0
                  ? 'positive'
                  : tempRangeValue < 0
                  ? 'negative'
                  : ''
              } ${props.pdfPreview ? 'pdfPreviewLegalFeesRangeText' : ''} ${
                props.changeEffect ? 'bold' : ''
              } ${
                props.absoluteSegmentHeight &&
                props.absoluteSegmentHeight.height < 50
                  ? 'oneLine'
                  : ''
              }`}
              id={`legalFeesRangeText-${props.singleSegmentIndex}`}
              style={{ fontSize: 14 }}
              data-openreplay-obscured
            >
              {tempRangeValue > 0
                ? `${Math.abs(tempRangeValue * 10)}${getText(
                    'description-244',
                    user.settings,
                    partiesFormat,
                  )}`
                : tempRangeValue < 0
                ? `${Math.abs(tempRangeValue * 10)}${getText(
                    'description-245',
                    user.settings,
                    partiesFormat,
                  )}`
                : `${getText('description-32', user.settings, partiesFormat)}`}
              {tempRangeValue !== 0 && (
                <span
                  style={{ fontWeight: 900 }}
                  data-textattribute="description-243"
                >
                  {' '}
                  {getText(
                    'description-243',
                    user.settings,
                    partiesFormat,
                  )}{' '}
                  {tempRangeValue > 0
                    ? getText('title-61c', user.settings, partiesFormat)
                    : getText('title-223', user.settings, partiesFormat)}
                </span>
              )}
            </p>
          </WhiteTooltip>
        </div>
        {!props.pdfPreview && (
          <div className="legalFeesRangeSliderContainer">
            <LegalFeesRangeSlider
              defaultRangeValue={rangeValue}
              singleSegmentIndex={props.singleSegmentIndex}
              setChangeEffect={props.setChangeEffect}
              isFirstLoad={props.isFirstLoad}
              setIsFirstLoad={props.setIsFirstLoad}
              findLegalFeesRangeText={findLegalFeesRangeText}
              ownRole={props.ownRole}
            />
          </div>
        )}
      </div>
    </>
  )
}
