import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { reportState } from '../../../../../../../../states/ReportState'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  date: string
}

export default function ReportCurrentDate(props: Props) {
  const [editMode, setEditMode] = useState(false)
  const freemium = useRecoilValue(freemiumState)
  const [report, setReport] = useRecoilState(reportState)
  const [localValue, setLocalValue] = useState(
    props.date === 'currentDate'
      ? DateTime.now().toFormat('dd LLL yyyy')
      : props.date,
  )

  function handleChangeDate() {
    if (props.date !== localValue) {
      let tempReport = deepCloneObject(report)
      tempReport.reportData.date = localValue
      //Mixpanel 159 (All)
      logActivity(freemium.isFreemium, 'Changed the date of the report')

      setReport(tempReport)
    }
  }

  return (
    <div className="currentDateContainer" id="currentDateContainerReport">
      <input
        className={`${editMode ? 'currentDateInput' : 'currentDate'} ${
          props.date === '' ? 'noDate' : ''
        }`}
        type={'text'}
        id="currentDateInputReport"
        onClick={() => setEditMode(true)}
        onChange={(e: any) => setLocalValue(e.target.value)}
        onBlur={(e: any) => {
          handleChangeDate()
          setEditMode(false)
        }}
        placeholder={'No date'}
        value={
          localValue === 'currentDate'
            ? DateTime.now().toFormat('dd LLL yyyy')
            : localValue
        }
        maxLength={18}
      />
    </div>
  )
}
