import { useState, useEffect, useRef } from 'react'

import InfoClickComponent from '../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import HoverComponent from '../../../../Components/InfoHover/HoverComponent/HoverComponent'
import firstProceedingsImg from '../../../../resources/images/281-firstProceedingsSymbol.svg'
import secondProceedingsImg from '../../../../resources/images/282-secondProceedingsSymbol.svg'

import removeSignImg from '../../../../resources/images/014-xSignGrayWhite.svg'
import multiline from '../../../../resources/images/063-multilineVersion2.svg'
import addSignImg from '../../../../resources/images/013-plusSignThinYellowBlue.svg'
import tickedImg from '../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../resources/images/018-untickedGrayBlue.svg'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import {
  DefaultSnapshotState,
  SnapshotSelectorObject,
  TreeTablesState,
} from '../../../../models/generalTypes'
import {
  AccessRole,
  AvailableLanguages,
  Currency,
  currencyFromMillions,
  currencyFromString,
  currencyToMillions,
  getCurrencyDescription,
  getReadableValueFromPartyFormatForDropDown,
  InterestViewOption,
  isCurrencyInMillions,
  NorwegianPartyFormat,
  NorwegianTypeOfInstance,
  PartyFormat,
  SwedishPartyFormat,
  SwedishTypeOfInstance,
  translateTypeOfInstance,
  TypeOfInstance,
  UndoRedoType,
  VideoPostName,
} from '../../../../models/enums'
import { ScenarioSnapshot } from '../../../../models/scenarioSnapshot'
import {
  deepCloneObject,
  logActivity,
} from '../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../services/changeGlobalSnapshot'
import { getAbs } from '../../../../services/formatNum'
import { keysIn, valuesIn } from 'lodash'
import DropDown from '../../../../Components/Inputs/DropDown/DropDown'
import InputDate from '../../../../Components/Inputs/InputDate/InputDate'
import InputNum from '../../../../Components/Inputs/InputNum/InputNum'
import { userState } from '../../../../states/UserState'
import { stringAndRoundTo2Decimals } from '../../../../Modules/DisputeModules/AppFunctions'
import { getText } from '../../../../services/textFunctions'
import VideoInfoButton from '../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import InputText from '../../../../Components/Inputs/InputText/InputText'
import useWindowSize from '../../../../customHooks/useWindowSize'
import AutocompleteDropDown from '../../../../Components/Inputs/DropDown/AutocompleteDropDown'
import { toggleAdditionalProceedingsInLegalCosts } from '../../../../services/legalCostsFunctions'
import { freemiumState } from '../../../../states/FreemiumState'
import PremiumFeatureIcon from '../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../../models/freemiumEnums'
import { treeTablesState } from '../../../../states/TreeTablesState'
import { secondTrialDateIsValid } from '../../../../services/validateGlobalState'
import Symbol from '../../../../Components/symbol/Symbol'

type Props = {
  handleChangeKeyValue: (e: any, key: keyof ScenarioSnapshot) => void
  setVideoPostName: (param: VideoPostName | undefined) => void
  errors: string[]
  ownRole: AccessRole
}

export default function Proceedings(props: Props) {
  const { width } = useWindowSize()
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)

  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const currentSnapshot = scenarioSnapshot.currentSnapshot

  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature
  const user = useRecoilValue(userState)
  const [inMillions, setInMillions] = useState(
    isCurrencyInMillions(currentSnapshot.currency),
  )
  const [freemium, setFreemium] = useRecoilState(freemiumState)
  const typeOfInstance =
    user.settings.language === AvailableLanguages.norwegian
      ? NorwegianTypeOfInstance
      : user.settings.language === AvailableLanguages.swedish
      ? SwedishTypeOfInstance
      : TypeOfInstance

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  useEffect(() => {
    setInMillions(isCurrencyInMillions(currentSnapshot.currency))
  }, [currentSnapshot.currency])

  function handleToggleHasSecondTrial(value: boolean) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'secondTrialAddRemoveButton',
      undoRedoType: UndoRedoType.button,
      value: value,
      key: 'hasSecondTrial',
    }

    let tempScenarioSnapshot = deepCloneObject(
      scenarioSnapshot,
    ) as DefaultSnapshotState

    tempScenarioSnapshot.currentSnapshot.probSecondTrialstep1 = undefined
    tempScenarioSnapshot.currentSnapshot.probSecondTrialstep2 = undefined
    tempScenarioSnapshot.currentSnapshot.probSecondTrial = 0
    tempScenarioSnapshot.currentSnapshot.secondTrialDate = undefined

    if (value === true) {
      tempScenarioSnapshot = toggleAdditionalProceedingsInLegalCosts(
        tempScenarioSnapshot,
        'add',
      )
    } else {
      tempScenarioSnapshot = toggleAdditionalProceedingsInLegalCosts(
        tempScenarioSnapshot,
        'remove',
      )
    }

    if (
      tempScenarioSnapshot.currentSnapshot.firstTrialDate === undefined &&
      tempScenarioSnapshot.currentSnapshot.secondTrialDate === undefined
    ) {
      tempScenarioSnapshot.currentSnapshot.interestViewOption =
        InterestViewOption.noInterest
    }

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)

    let tempTreeTables = deepCloneObject(treeTables) as TreeTablesState
    if (!secondTrialDateIsValid(tempScenarioSnapshot.currentSnapshot)) {
      for (let treeTablesInfo of tempTreeTables.treeTablesInfo) {
        if (
          treeTablesInfo.treeInterestViewOption !==
          InterestViewOption.noInterest
        ) {
          treeTablesInfo.treeInterestViewOption = InterestViewOption.noInterest
        }
      }
    }
    setTreeTables(tempTreeTables)
  }

  function handleSecProb(
    e: any,
    key: 'probSecondTrialstep1' | 'probSecondTrialstep2',
  ) {
    const step1 = e.target.value
    const step2 =
      key === 'probSecondTrialstep1'
        ? currentSnapshot.probSecondTrialstep2
        : currentSnapshot.probSecondTrialstep1

    let secondTrialProb =
      step1 !== undefined && step2 !== undefined ? (step1! * step2!) / 100 : 0

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: e.target.id,
      undoRedoType: UndoRedoType.input,
      value: step1,
      key: key,
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot.currentSnapshot.probSecondTrial = secondTrialProb

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)
  }

  const handleChangeToMillions = () => {
    props.handleChangeKeyValue(
      {
        target: {
          id: 'currencyDropdown',
          value: isCurrencyInMillions(currentSnapshot.currency)
            ? currencyFromMillions(currentSnapshot.currency)
            : currencyToMillions(currentSnapshot.currency),
        },
      },
      'currency',
    )
  }

  const millionsText = () => {
    //textAttribute="description-154"
    return `${getText('description-154', user.settings)} ${currencyFromMillions(
      currentSnapshot.currency,
    )} (${currencyToMillions(currentSnapshot.currency)})`
  }

  return (
    <div className="tool-component" id="courtDetailsComp">
      <h2
        className="tool-component-title"
        id="proccedings-title"
        data-textattribute="title-31"
      >
        {getText('title-31', user.settings)}
        <VideoInfoButton
          textAttribute="tooltip-19"
          postName={VideoPostName.proceedings}
          setVideoPostName={props.setVideoPostName}
        />
      </h2>
      <p
        className="tool-component-subtitle marginLeft1"
        id="proccedings-description"
        data-textattribute="description-33"
      >
        {getText('description-33', user.settings)}
      </p>

      <h1
        className="tool-component-second-title marginLeft4"
        id="definePartiesTitle"
        data-textattribute="title-202"
      >
        {getText('title-202', user.settings)}
      </h1>

      <div
        className="tool-component-input-container marginLeft3 first-container"
        style={{ position: 'relative' }}
      >
        <Symbol
          targetId="container-partyFormatOwnDropdown"
          symbolType="client"
          targetType="dropdown"
        />
        <DropDown
          label={getText('label-83', user.settings)}
          labelTextAttribute="label-83"
          width={290}
          id="partyFormatOwnDropdown"
          value={
            currentSnapshot.partyFormatOwn !== undefined
              ? getReadableValueFromPartyFormatForDropDown(
                  currentSnapshot.partyFormatOwn,
                  user.settings.language,
                )
              : ''
          }
          options={
            user.settings.language === AvailableLanguages.norwegian
              ? [
                  ...valuesIn(NorwegianPartyFormat),
                  getText('label-106', user.settings),
                ]
              : user.settings.language === AvailableLanguages.swedish
              ? [
                  ...valuesIn(SwedishPartyFormat),
                  getText('label-106', user.settings),
                ]
              : [...valuesIn(PartyFormat), getText('label-106', user.settings)]
          }
          tabIndex={1}
          name="partyFormatOwn"
          onChange={(e: any) => {
            if (
              freemium.isFreemium &&
              e.target.value === getText('label-106', user.settings)
            ) {
              setFreemium({
                ...freemium,
                showMessage: FreemiumMessageType.General,
              })
            } else {
              props.handleChangeKeyValue(e, 'partyFormatOwn')
            }
          }}
          error={props.errors.includes('partyFormatOwnDropdown')}
          errorMessage={getText('error-85', user.settings)}
          errorTextAttribute="error-85"
          disabled={props.ownRole === AccessRole.VIEWER}
        />
        {currentSnapshot.partyFormatOwn !== undefined &&
          (currentSnapshot.partyFormatOwn === '' ||
            !keysIn(PartyFormat).includes(currentSnapshot.partyFormatOwn)) && (
            <>
              <InputText
                label={getText('label-85', user.settings)}
                labelTextAttribute={'label-85'}
                id={'customPartyFormatOwn'}
                value={currentSnapshot.partyFormatOwn}
                className="customPartyFormatInput"
                tabIndex={2}
                name={'customPartyFormatOwn'}
                onChange={(e: any) =>
                  props.handleChangeKeyValue(e, 'partyFormatOwn')
                }
                error={
                  props.errors.includes('customPartyFormatOwn') ||
                  props.errors.includes('partyFormatOwnDropdown')
                }
                errorMessage={
                  props.errors.includes('customPartyFormatOwn')
                    ? getText('error-87', user.settings)
                    : ''
                }
                errorTextAttribute={
                  props.errors.includes('customPartyFormatOwn')
                    ? 'error-87'
                    : 'error-'
                }
                maxLength={45}
                width={width < 910 ? 240 : 330}
                disabled={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
                withoutDisabledMessage={freemium.isFreemium}
              />
            </>
          )}
      </div>

      <div className="tool-component-input-container marginLeft7">
        <Symbol
          targetId="container-partyFormatOtherDropdown"
          symbolType="opposingParty"
          targetType="dropdown"
        />
        <DropDown
          label={getText('label-84', user.settings)}
          labelTextAttribute="label-84"
          width={290}
          id="partyFormatOtherDropdown"
          value={
            currentSnapshot.partyFormatOther !== undefined
              ? getReadableValueFromPartyFormatForDropDown(
                  currentSnapshot.partyFormatOther,
                  user.settings.language,
                )
              : ''
          }
          options={
            user.settings.language === AvailableLanguages.norwegian
              ? [
                  ...valuesIn(NorwegianPartyFormat),
                  getText('label-106', user.settings),
                ]
              : user.settings.language === AvailableLanguages.swedish
              ? [
                  ...valuesIn(SwedishPartyFormat),
                  getText('label-106', user.settings),
                ]
              : [...valuesIn(PartyFormat), getText('label-106', user.settings)]
          }
          tabIndex={3}
          name="partyFormatOther"
          onChange={(e: any) => {
            if (
              freemium.isFreemium &&
              e.target.value === getText('label-106', user.settings)
            ) {
              setFreemium({
                ...freemium,
                showMessage: FreemiumMessageType.General,
              })
            } else {
              props.handleChangeKeyValue(e, 'partyFormatOther')
            }
          }}
          error={props.errors.includes('partyFormatOtherDropdown')}
          errorMessage={getText('error-85', user.settings)}
          errorTextAttribute="error-85"
          disabled={props.ownRole === AccessRole.VIEWER}
        />
        {currentSnapshot.partyFormatOther !== undefined &&
          (currentSnapshot.partyFormatOther === '' ||
            !keysIn(PartyFormat).includes(
              currentSnapshot.partyFormatOther,
            )) && (
            <>
              <InputText
                label={getText('label-86', user.settings)}
                labelTextAttribute={'label-86'}
                id={'customPartyFormatOther'}
                value={currentSnapshot.partyFormatOther}
                className="customPartyFormatInput"
                tabIndex={4}
                name={'customPartyFormatOther'}
                onChange={(e: any) =>
                  props.handleChangeKeyValue(e, 'partyFormatOther')
                }
                error={
                  props.errors.includes('customPartyFormatOther') ||
                  props.errors.includes('partyFormatOtherDropdown')
                }
                errorMessage={
                  props.errors.includes('customPartyFormatOther')
                    ? getText('error-89', user.settings)
                    : ''
                }
                errorTextAttribute={
                  props.errors.includes('customPartyFormatOther')
                    ? 'error-89'
                    : 'error-'
                }
                maxLength={45}
                width={width < 910 ? 240 : 330}
                disabled={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
                withoutDisabledMessage={freemium.isFreemium}
              />
            </>
          )}
      </div>

      <h1
        className="tool-component-second-title marginLeft4"
        id="proccedingsDetailsTitle"
        data-textattribute="title-203"
      >
        {getText('title-203', user.settings)}
        <img
          src={firstProceedingsImg}
          alt="firstProceedingsImg"
          className="firstProceedingsImg"
        />
      </h1>

      <div className="tool-component-input-container marginLeft3 first-container">
        <AutocompleteDropDown
          label={getText('label-62', user.settings)}
          labelTextAttribute="label-62"
          width={280}
          id="currencyDropdown"
          value={currentSnapshot.currency}
          options={keysIn(Currency).filter((cur, index) => index % 2 === 0)}
          getDescriptions={(currency) =>
            `(${getText(
              getCurrencyDescription(currencyFromString(currency)),
              user.settings,
            )})`
          }
          onOpen={() => {
            if (isCurrencyInMillions(currentSnapshot.currency)) {
              handleChangeToMillions()
            }
          }}
          tabIndex={5}
          name="currency"
          onChange={(e: any) => props.handleChangeKeyValue(e, 'currency')}
          disabled={props.ownRole === AccessRole.VIEWER}
          alwaysShrink
        />
        <InfoClickComponent
          infoClickText={getText('infoButton-1', user.settings)}
          infoClickTextAttribute="infoButton-1"
          idName="currency"
          hoverElement={hoverElement}
          setHoverElement={setHoverElement}
          noHelperText
        />
        {props.ownRole !== AccessRole.VIEWER && (
          <div
            style={{
              marginBottom: '15px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={inMillions ? tickedImg : unTickedImg}
              alt={inMillions ? 'tickedImg' : 'unTickedImg'}
              id="inMillionsTick"
              tabIndex={6}
              onClick={handleChangeToMillions}
              style={{
                cursor: 'pointer',
                marginRight: '6px',
              }}
            />
            <p onClick={handleChangeToMillions}>{millionsText()}</p>
          </div>
        )}
      </div>

      <div className="tool-component-input-container marginLeft3">
        <DropDown
          label={getText('label-63', user.settings)}
          labelTextAttribute="label-63"
          id="typeOfInstance"
          width={280}
          value={
            currentSnapshot.court
              ? translateTypeOfInstance(
                  currentSnapshot.court,
                  AvailableLanguages.english,
                  user.settings.language,
                )
              : ''
          }
          options={valuesIn(typeOfInstance)}
          tabIndex={7}
          name="court"
          onChange={(e: any) => props.handleChangeKeyValue(e, 'court')}
          error={props.errors.includes('typeOfInstance')}
          errorMessage={getText('error-13', user.settings)}
          errorTextAttribute="error-13"
          disabled={props.ownRole === AccessRole.VIEWER}
        />
        <InfoClickComponent
          infoClickText={getText('infoButton-2', user.settings)}
          infoClickTextAttribute="infoButton-2"
          idName="court"
          hoverElement={hoverElement}
          setHoverElement={setHoverElement}
          noHelperText
        />
      </div>

      <div className="tool-component-input-container marginLeft3">
        <InputDate
          label={
            currentSnapshot.court === TypeOfInstance.Public_Court
              ? getText('label-3', user.settings)
              : currentSnapshot.court === TypeOfInstance.Arbitration
              ? getText('label-4', user.settings)
              : getText('label-5', user.settings)
          }
          labelTextAttribute={
            currentSnapshot.court === TypeOfInstance.Public_Court
              ? 'label-3'
              : currentSnapshot.court === TypeOfInstance.Arbitration
              ? 'label-4'
              : 'label-5'
          }
          value={currentSnapshot.firstTrialDate}
          className="form-control robotoNumbers"
          width="280px"
          name="firstTrialDate"
          onChange={(e: any) => props.handleChangeKeyValue(e, 'firstTrialDate')}
          error={props.errors.includes('firstTrialDate')}
          errorMessage={getText('error-5', user.settings)}
          errorTextAttribute="error-5"
          id="firstTrialDate"
          tabIndex={8}
          disabled={props.ownRole === AccessRole.VIEWER}
        />
        <InfoClickComponent
          infoClickText={
            currentSnapshot.court === TypeOfInstance.Public_Court
              ? getText('infoButton-3', user.settings)
              : currentSnapshot.court === TypeOfInstance.Arbitration
              ? getText('infoButton-4', user.settings)
              : getText('infoButton-5', user.settings)
          }
          infoClickTextAttribute={
            currentSnapshot.court === TypeOfInstance.Public_Court
              ? 'infoButton-3'
              : currentSnapshot.court === TypeOfInstance.Arbitration
              ? 'infoButton-4'
              : 'infoButton-5'
          }
          idName="firstTrialDate"
          hoverElement={hoverElement}
          setHoverElement={setHoverElement}
        />
      </div>

      {currentSnapshot.hasSecondTrial ? (
        <>
          <div
            className="tool-component-submenu-container"
            style={{ maxWidth: '747px' }}
            id="secondTrialAddRemoveButton"
          >
            <div
              className="tool-component-remove-button-container"
              onClick={() => {
                if (props.ownRole !== AccessRole.VIEWER) {
                  handleToggleHasSecondTrial(false)
                  //Mixpanel 26
                  logActivity(false, 'Removed second trial submenu')
                }
              }}
              tabIndex={9}
            >
              {props.ownRole !== AccessRole.VIEWER && (
                <div className="addSignContainer">
                  <img src={removeSignImg} alt="removeSignImg" />
                </div>
              )}
              <p
                id="proceedings-submenuTitle-on"
                data-textattribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'submenu-1'
                    : 'submenu-2'
                }
              >
                {currentSnapshot.court === TypeOfInstance.Public_Court
                  ? getText('submenu-1', user.settings)
                  : getText('submenu-2', user.settings)}
              </p>
              <img
                src={secondProceedingsImg}
                alt="secondProceedingsImg"
                className="secondProceedingsImg"
              />
            </div>
            <div className="tool-component-submenu-input-container probSecondTrialstep1">
              <InputNum
                label={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('label-6', user.settings)
                    : getText('label-7', user.settings)
                }
                labelTextAttribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'label-6'
                    : 'label-7'
                }
                className="robotoNumbers"
                suffix="%"
                value={getAbs(currentSnapshot.probSecondTrialstep1)}
                id="probSecondTrialstep1"
                maxValue={100}
                width={340}
                maxNumberOfDecimals={0}
                name="probSecondTrialstep1"
                onChange={(e: any) => handleSecProb(e, 'probSecondTrialstep1')}
                error={props.errors.includes('probSecondTrialstep1')}
                errorMessage={getText('error-15', user.settings)}
                errorTextAttribute="error-15"
                tabIndex={10}
                readOnly={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
                disabled={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
              />
              <InfoClickComponent
                infoClickText={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('infoButton-6', user.settings)
                    : getText('infoButton-7', user.settings)
                }
                infoClickTextAttribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'infoButton-6'
                    : 'infoButton-7'
                }
                idName="probSecondTrialstep1"
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
              />
            </div>

            <div className="tool-component-submenu-input-container">
              <InputNum
                label={getText('label-8', user.settings)}
                labelTextAttribute="label-8"
                suffix="%"
                className="robotoNumbers"
                value={getAbs(currentSnapshot.probSecondTrialstep2)}
                id="probSecondTrialstep2"
                maxValue={100}
                maxNumberOfDecimals={0}
                width={340}
                name="probSecondTrialstep2"
                onChange={(e: any) => handleSecProb(e, 'probSecondTrialstep2')}
                error={props.errors.includes('probSecondTrialstep2')}
                errorMessage={getText('error-15', user.settings)}
                errorTextAttribute="error-15"
                tabIndex={11}
                readOnly={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
                disabled={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
              />
              <InfoClickComponent
                infoClickText={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('infoButton-8', user.settings)
                    : getText('infoButton-9', user.settings)
                }
                infoClickTextAttribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'infoButton-8'
                    : 'infoButton-9'
                }
                idName="probSecondTrialstep2"
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
              />
            </div>

            <div className="secondProbContainer">
              <img src={multiline} alt="multiline" className="multiline" />
              <p
                className="tool-component-submenu-text"
                id="finalProbabilityText"
                data-textattribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'description-34'
                    : 'description-35'
                }
              >
                {currentSnapshot.court === TypeOfInstance.Public_Court
                  ? getText('description-34', user.settings)
                  : getText('description-35', user.settings)}{' '}
                <span>
                  {!currentSnapshot.probSecondTrial
                    ? 0
                    : stringAndRoundTo2Decimals(
                        currentSnapshot.probSecondTrial,
                        user.settings,
                      )}
                  %
                </span>
                .
              </p>
            </div>

            <div className="tool-component-submenu-input-container">
              <InputDate
                label={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('label-9', user.settings)
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? getText('label-10', user.settings)
                    : getText('label-11', user.settings)
                }
                labelTextAttribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'label-9'
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? 'label-10'
                    : 'label-11'
                }
                value={currentSnapshot.secondTrialDate}
                className="form-control robotoNumbers"
                id="secondTrialDate"
                width={'350px'}
                name="secondTrialDate"
                onChange={(e: any) =>
                  props.handleChangeKeyValue(e, 'secondTrialDate')
                }
                error={
                  props.errors.includes('secondTrialDate') ||
                  props.errors.includes('secondTrialDate:validation')
                }
                errorMessage={
                  props.errors.includes('secondTrialDate:validation')
                    ? currentSnapshot.court === TypeOfInstance.Public_Court
                      ? getText('error-6', user.settings)
                      : currentSnapshot.court === TypeOfInstance.Arbitration
                      ? getText('error-7', user.settings)
                      : getText('error-8', user.settings)
                    : getText('error-5', user.settings)
                }
                errorTextAttribute={
                  props.errors.includes('secondTrialDate:validation')
                    ? currentSnapshot.court === TypeOfInstance.Public_Court
                      ? 'error-6'
                      : currentSnapshot.court === TypeOfInstance.Arbitration
                      ? 'error-7'
                      : 'error-8'
                    : 'error-5'
                }
                tabIndex={12}
                disabled={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
              />
              <InfoClickComponent
                infoClickText={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('infoButton-10', user.settings)
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? getText('infoButton-11', user.settings)
                    : getText('infoButton-12', user.settings)
                }
                infoClickTextAttribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'infoButton-10'
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? 'infoButton-11'
                    : 'infoButton-12'
                }
                idName="secondTrialDate"
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
              />
            </div>
          </div>
          <div
            className="tool-component-spacer"
            style={{ height: '13px' }}
          ></div>
        </>
      ) : (
        <>
          {props.ownRole !== AccessRole.VIEWER && (
            <>
              <div
                className="tool-component-add-button-container marginLeft2"
                id="secondTrialAddRemoveButton"
                tabIndex={13}
                onClick={() => {
                  if (freemium.isFreemium) {
                    setFreemium({
                      ...freemium,
                      showMessage: FreemiumMessageType.General,
                    })
                  } else {
                    handleToggleHasSecondTrial(true)
                    //Mixpanel 25
                    logActivity(false, 'Added second trial submenu')
                  }
                }}
                onMouseEnter={() => {
                  secondaryHover.current = 'addSecondInstance'
                  setTimeout(() => {
                    if (secondaryHover.current === 'addSecondInstance') {
                      setHoverElement('addSecondInstance')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                {hoverElement === 'addSecondInstance' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '-10px', right: '-220px' }}
                  >
                    <HoverComponent
                      hoverText={
                        currentSnapshot.court === TypeOfInstance.Public_Court
                          ? getText('hover-4', user.settings)
                          : getText('hover-5', user.settings)
                      }
                      textAttribute={
                        currentSnapshot.court === TypeOfInstance.Public_Court
                          ? 'hover-4'
                          : 'hover-5'
                      }
                      id="secondTrialAddRemoveButton"
                    />
                  </div>
                ) : null}
                <div className="addSignContainer">
                  <img src={addSignImg} alt="addSign" />
                </div>
                <p
                  id="proceedings-submenuTitle-off"
                  data-textattribute={
                    currentSnapshot.court === TypeOfInstance.Public_Court
                      ? 'submenu-3'
                      : 'submenu-4'
                  }
                >
                  {currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('submenu-3', user.settings)
                    : getText('submenu-4', user.settings)}
                </p>
                <img
                  src={secondProceedingsImg}
                  alt="secondProceedingsImg"
                  className="secondProceedingsImg"
                />
              </div>
              {freemium.isFreemium && !currentSnapshot.hasSecondTrial && (
                <PremiumFeatureIcon
                  targetId={'secondTrialAddRemoveButton'}
                  forTextWithSymbol
                />
              )}
            </>
          )}

          <div
            className="tool-component-spacer"
            style={{ height: '13px' }}
          ></div>
        </>
      )}
    </div>
  )
}
