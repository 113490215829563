import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { LegalCostsProceedings } from '../../../../../../../../../models/enums'
import { DateTime } from 'luxon'
import { getStyleFromWhitelabel } from '../../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'
import { User } from '../../../../../../../../../models/user'
import { getText } from '../../../../../../../../../services/textFunctions'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  proceedings: LegalCostsProceedings
  hasSecondTrial: boolean
  date: string | undefined
  reportWhitelabel?: ReportWhitelabel
  user: User
}

export default function ClaimsAndLegalCostsDocument(props: Props) {
  const styles = StyleSheet.create({
    proceedingsDateComponent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      paddingRight: '0.5px',
    },
    firstproceedingsDateComponent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      paddingRight: '0.5px',
      marginTop: '2px',
    },
    proceedingsDateText: {
      fontSize: '8px',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).marginTop,
    },
    proceedingsDate: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.medium,
        'noRoboto',
        true,
      ).color,
    },
    sup: {
      fontSize: '4px',
      position: 'absolute',
      top: '1px',
      right: '0px',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.none,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins',
    },
  })

  //data-textattribute='description-268'
  //data-textattribute='description-269'
  //data-textattribute='description-270'
  const proceedingsText = props.hasSecondTrial
    ? props.proceedings === LegalCostsProceedings.firstProceedings
      ? getText('description-268', props.user.settings)
      : getText('description-269', props.user.settings)
    : getText('description-270', props.user.settings)

  return (
    <>
      <View
        style={
          props.proceedings === LegalCostsProceedings.firstProceedings
            ? styles.firstproceedingsDateComponent
            : styles.proceedingsDateComponent
        }
      >
        <Text style={styles.proceedingsDateText}>{proceedingsText}</Text>
        <Text style={styles.proceedingsDate}>
          {' '}
          {DateTime.fromISO(props.date as string).toFormat('dd LLL yyyy')}
        </Text>
        <Text style={styles.sup}>2</Text>
      </View>
    </>
  )
}
