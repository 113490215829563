import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  AvailableLanguages,
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../models/enums'
import {
  getSplitText,
  getText,
} from '../../../../../../../../services/textFunctions'
import { reportState } from '../../../../../../../../states/ReportState'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../../../../states/UserState'
import editButtonImg from '../../../../../../../../resources/images/027-renameSign.svg'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  getLegalCosts,
  legalFeesZero,
} from '../../../../../../../../services/legalCostsFunctions'
import { freemiumState } from '../../../../../../../../states/FreemiumState'
import { getLegalCostsSingleValue } from '../../../../../../../../services/resultsFunctions'

type Props = {
  legalCostsDescriptions: ReportLegalCostsType
  reportWhitelabel?: ReportWhitelabel
}

export default function LegalCostsPreviewComponent(props: Props) {
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [report, setReport] = useRecoilState(reportState)

  const hasSecondTrial = currentSnapshot.probSecondTrial! > 0

  const legalCostsObj = currentSnapshot.legalCosts
  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]

  const firstProceedingsDefaultMessage = hasSecondTrial
    ? getText('title-129', user.settings)
    : getText('title-130', user.settings)

  const secondProceedingsDefaultMessage =
    currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        getText('title-131a', user.settings)
      : //title-131b
        getText('title-131b', user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  const [isEditing, setIsEditing] = useState<'first' | 'second' | undefined>(
    undefined,
  )
  const [showEditButton, setShowEditButton] = useState<
    'first' | 'second' | undefined
  >(undefined)
  const [editableFirstProceedingsText, setEditableFirstProceedingsText] =
    useState(firstProceedingsText)
  const [editableSecondProceedingsText, setEditableSecondProceedingsText] =
    useState(secondProceedingsText)

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        if (document.getElementById(`proceedingsText-${isEditing}`)) {
          document.getElementById(`proceedingsText-${isEditing}`)!.focus()
        }
      }, 20)
    }
    // eslint-disable-next-line
  }, [isEditing])

  useEffect(() => {
    window.addEventListener('mousedown', mouseDownLeaveEditMode)

    return () => {
      window.removeEventListener('mousedown', mouseDownLeaveEditMode)
    }
    // eslint-disable-next-line
  }, [editableFirstProceedingsText, isEditing, editableSecondProceedingsText])

  function mouseDownLeaveEditMode(e: any) {
    if (isEditing && !e.target.id.includes(`proceedingsText`)) {
      setIsEditing(undefined)
    }
  }

  useEffect(() => {
    const handle = setTimeout(() => {
      let tempReport = deepCloneObject(report)
      let legalCostsIndex = report.reportData.sections.findIndex(
        (data) => data.contents.legalCosts !== undefined,
      )
      if (
        tempReport.reportData.sections[legalCostsIndex].contents.legalCosts!
          .firstOwnLegalCostsDescription !== editableFirstProceedingsText ||
        tempReport.reportData.sections[legalCostsIndex].contents.legalCosts!
          .secondOwnLegalCostsDescription !== editableSecondProceedingsText
      ) {
        //Mixpanel 155 (All)
        logActivity(
          freemium.isFreemium,
          'Renamed the cost of proceedings in the legal costs in the report',
        )
      }
      tempReport.reportData.sections[
        legalCostsIndex
      ].contents.legalCosts!.firstOwnLegalCostsDescription =
        editableFirstProceedingsText
      tempReport.reportData.sections[
        legalCostsIndex
      ].contents.legalCosts!.secondOwnLegalCostsDescription =
        editableSecondProceedingsText
      setReport(tempReport)
    }, 300)

    return () => clearTimeout(handle)
    // eslint-disable-next-line
  }, [editableFirstProceedingsText, isEditing, editableSecondProceedingsText])

  const getLegalCostsValues = (
    party: LegalCostsParty,
    proceedings: LegalCostsProceedings,
  ) => {
    return (
      <>
        <p
          className={`legalCostsPreviewText robotoNumbersBold bold ${
            proceedings === LegalCostsProceedings.additionalProceedings
              ? 'divider'
              : ''
          }`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'roboto',
          )}
        >
          {getLegalCostsSingleValue(
            party === LegalCostsParty.clientLegalCosts
              ? proceedings === LegalCostsProceedings.firstProceedings
                ? legalCostsObj.clientLegalCosts.firstProceedings
                    .estimatedCosts!
                : legalCostsObj.clientLegalCosts.additionalProceedings!
                    .estimatedCosts!
              : proceedings === LegalCostsProceedings.firstProceedings
              ? legalCostsObj.opposingPartyLegalCosts.firstProceedings
                  .estimatedCosts!
              : legalCostsObj.opposingPartyLegalCosts.additionalProceedings!
                  .estimatedCosts!,
            currentSnapshot.currency,
            user.settings,
          )}
        </p>
        <p
          className={`legalCostsPreviewText robotoNumbers value`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
          )}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'recoverableCosts',
              party,
              proceedings,
              currentSnapshot,
              user.settings,
            ),
            currentSnapshot.currency,
            user.settings,
          )}
        </p>
        <p
          className={`legalCostsPreviewText robotoNumbers value`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
          )}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'incurredCosts',
              party,
              proceedings,
              currentSnapshot,
              user.settings,
            ),
            currentSnapshot.currency,
            user.settings,
          )}
        </p>
      </>
    )
  }

  const getLegalCostsTexts = (secondTrial?: boolean) => {
    return (
      <>
        {!secondTrial && (
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className={`legalCostsPreviewText party invisible`}
          >
            {'No Text'}
          </p>
        )}
        <div
          className={`legalCostsPreviewSmallColumn maxWidth ${
            (!secondTrial && hasSecondTrial) || currentSnapshot.hasInsurance
              ? 'divider'
              : ''
          }`}
        >
          <div
            onClick={() => {
              setIsEditing(secondTrial ? 'second' : 'first')
              setShowEditButton(undefined)
            }}
            onMouseEnter={() =>
              setShowEditButton(secondTrial ? 'second' : 'first')
            }
            onMouseLeave={() => setShowEditButton(undefined)}
            className="legalCostsPreviewEditButtonContainer"
          >
            {((secondTrial && showEditButton === 'second') ||
              (!secondTrial && showEditButton === 'first')) && (
              <>
                <img src={editButtonImg} alt="editButtonImg" />
                <p
                  id={`pdfPreviewEditButtonText-legalCostFirst`}
                  data-textattribute="button-88"
                >
                  {getText('button-88', user.settings)}
                </p>
              </>
            )}
          </div>
          {(isEditing === 'second' && secondTrial) ||
          (isEditing === 'first' && !secondTrial) ? (
            <input
              type="text"
              className="proceedingsTextInput"
              value={
                secondTrial
                  ? editableSecondProceedingsText
                  : editableFirstProceedingsText
              }
              placeholder={
                secondTrial
                  ? secondProceedingsDefaultMessage
                  : firstProceedingsDefaultMessage
              }
              id={`proceedingsText-${isEditing}`}
              onChange={(e) => {
                if (secondTrial) {
                  setEditableSecondProceedingsText(e.target.value)
                } else {
                  setEditableFirstProceedingsText(e.target.value)
                }
              }}
              maxLength={40}
              style={{
                ...getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.medium,
                  'noRoboto',
                ),
                width: '100%',
              }}
            />
          ) : (
            <p
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.medium,
                'noRoboto',
              )}
              className={`legalCostsPreviewText semi-bold editable`}
              onMouseEnter={() =>
                setShowEditButton(secondTrial ? 'second' : 'first')
              }
              onMouseLeave={() => setShowEditButton(undefined)}
              onClick={() => {
                setIsEditing(secondTrial ? 'second' : 'first')
                setShowEditButton(undefined)
              }}
            >
              {secondTrial
                ? secondProceedingsText.length > 0
                  ? secondProceedingsText
                  : secondProceedingsDefaultMessage
                : firstProceedingsText.length > 0
                ? firstProceedingsText
                : firstProceedingsDefaultMessage}
              :
            </p>
          )}
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className={`legalCostsPreviewText description`}
            data-textattribute="label-89"
          >
            {getText('label-89', user.settings)}:
          </p>
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className={`legalCostsPreviewText description`}
            data-textattribute="label-91"
          >
            {getText('label-91', user.settings)}:
          </p>
        </div>
      </>
    )
  }

  const getInsuranceValues = () => {
    return (
      <>
        <p
          className={`legalCostsPreviewText bold divider invisible`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
          )}
        >
          {'No Value'}
        </p>
        <p
          className={`legalCostsPreviewText robotoNumbers value`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
          )}
        >
          {getLegalCostsSingleValue(
            currentSnapshot.minimumExcessFee!,
            currentSnapshot.currency,
            user.settings,
          )}
        </p>
        <p
          className={`legalCostsPreviewText robotoNumbers value`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
          )}
        >
          {getLegalCostsSingleValue(
            currentSnapshot.insuranceExcessPercentage!,
            currentSnapshot.currency,
            user.settings,
            true,
          )}
        </p>
        <p
          className={`legalCostsPreviewText robotoNumbers value`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
          )}
        >
          {getLegalCostsSingleValue(
            currentSnapshot.maxInsurance!,
            currentSnapshot.currency,
            user.settings,
          )}
        </p>
      </>
    )
  }

  const getInsuranceText = () => {
    const splitter =
      user.settings.language === AvailableLanguages.swedish
        ? 's rättsskyddsförsäkring'
        : user.settings.language === AvailableLanguages.norwegian
        ? 's forsikring'
        : '’s'

    const substringLength =
      user.settings.language === AvailableLanguages.swedish
        ? 12
        : user.settings.language === AvailableLanguages.norwegian
        ? 12
        : 10

    const stringLength =
      user.settings.language === AvailableLanguages.swedish
        ? 40
        : user.settings.language === AvailableLanguages.norwegian
        ? 40
        : 40
    return (
      <>
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.medium,
            'noRoboto',
          )}
          className={`legalCostsPreviewText semi-bold noEllipsis`}
          data-textattribute="title-254"
        >
          {getSplitText(
            `${getText('label-97', user.settings, partiesFormat)}${getText(
              'title-254',
              user.settings,
            )}`,
            splitter,
            stringLength,
            substringLength,
          )}
        </p>
        <div className={`legalCostsPreviewSmallColumn`}>
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className={`legalCostsPreviewText description`}
            data-textattribute="label-36"
          >
            {getText('label-36', user.settings)}:
          </p>
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className={`legalCostsPreviewText description`}
            data-textattribute="title-255"
          >
            {getText('title-255', user.settings)}:
          </p>
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className={`legalCostsPreviewText description`}
            data-textattribute="title-256"
          >
            {getText('title-256', user.settings)}:
          </p>
        </div>
      </>
    )
  }

  return (
    <div className="legalCostsPreviewContainer">
      {legalFeesZero(currentSnapshot) ? (
        <div
          className="noLegalCostsParagraph"
          id="noLegalCostsParagraphPreviewPdfReport"
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
        >
          {getText('description-52', user.settings)}
        </div>
      ) : (
        <>
          <div className="legalCostsPreviewMainColumn for-titles">
            {getLegalCostsTexts()}
            {hasSecondTrial && getLegalCostsTexts(hasSecondTrial)}
            {currentSnapshot.hasInsurance && getInsuranceText()}
          </div>
          <div className="legalCostsPreviewMainColumn">
            <div
              className={`legalCostsPreviewSmallColumn maxWidth ${
                hasSecondTrial ? 'divider' : ''
              }`}
            >
              <p
                className={`legalCostsPreviewText party`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.bold,
                  'noRoboto',
                )}
              >
                {getText('label-97', user.settings, partiesFormat)}
              </p>
              <div className="legalCostsPreviewSmallColumn">
                {getLegalCostsValues(
                  LegalCostsParty.clientLegalCosts,
                  LegalCostsProceedings.firstProceedings,
                )}
                {hasSecondTrial &&
                  getLegalCostsValues(
                    LegalCostsParty.clientLegalCosts,
                    LegalCostsProceedings.additionalProceedings,
                  )}
                {currentSnapshot.hasInsurance && getInsuranceValues()}
              </div>
            </div>
          </div>
          <div className="legalCostsPreviewMainColumn">
            <div
              className={`legalCostsPreviewSmallColumn maxWidth ${
                hasSecondTrial ? 'divider' : ''
              }`}
            >
              <p
                className={`legalCostsPreviewText party`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.bold,
                  'noRoboto',
                )}
              >
                {getText('label-98', user.settings, partiesFormat)}
              </p>
              <div className="legalCostsPreviewSmallColumn">
                {getLegalCostsValues(
                  LegalCostsParty.opposingPartyLegalCosts,
                  LegalCostsProceedings.firstProceedings,
                )}
                {hasSecondTrial &&
                  getLegalCostsValues(
                    LegalCostsParty.opposingPartyLegalCosts,
                    LegalCostsProceedings.additionalProceedings,
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
