import { keysIn, valuesIn } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  AvailableLanguages,
  Currency,
  currencyFromString,
  DateFormatOption,
  dateFormatOptionFromString,
  getCurrencyDescription,
  getReadableValueFromPartyFormatForReport,
  NorwegianPartyFormat,
  NumberFormatOption,
  numberFormatOptionFromFormat,
  PartyFormat,
  partyFormatFromString,
  SwedishPartyFormat,
} from '../../../../models/enums'
import { User } from '../../../../models/user'
import { UserSettings } from '../../../../models/userSettings'
import {
  deepCloneObject,
  findHandlingErrorState,
  logActivity,
} from '../../../../services/commonFunctions'
import { saveUserSettings } from '../../../../services/requests'
import { getText } from '../../../../services/textFunctions'
import { handlingErrorsState } from '../../../../states/HandlingErrorsState'
import { userState } from '../../../../states/UserState'
import FormatComponent from './components/FormatComponent'
import { freemiumState } from '../../../../states/FreemiumState'
import TermsOfUse from '../1_accountDetails/components/TermsOfUse'
import { saveFreemiumPreferences } from '../../../../freemiumServices/freemiumStorage'
import { AxiosResponse } from 'axios'

const Preferences = () => {
  const [user, setUser] = useRecoilState(userState)
  const freemium = useRecoilValue(freemiumState)
  const [hoverElement, setHoverElement] = useState('')
  const [showSaved, setShowSaved] = useState('')
  const [showSaveButtons, setShowSaveButtons] = useState<string[]>([])
  const [dateFormat, setDateFormat] = useState(
    user.settings.formats.dateFormat.toString(),
  )
  const [numberFormat, setNumberFormat] = useState(
    numberFormatOptionFromFormat(user.settings.formats.numberFormat).toString(),
  )
  const [partyFormat, setPartyFormat] = useState(
    user.settings.formats.partyFormat,
  )
  const [opposingPartyFormat, setOpposingPartyFormat] = useState(
    user.settings.formats.opposingPartyFormat,
  )
  const [partiesErrorMessage, setPartiesErrorMessage] = useState<
    string | undefined
  >(undefined)
  const [defaultCurrency, setDefaultCurrency] = useState(
    user.settings.formats.currency,
  )
  const [loading, setLoading] = useState('')
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  const onMouseDown = (e: any) => {
    setHoverElement('')
  }

  const onChange = (newValue: string, id: string) => {
    let addId = false

    switch (id) {
      case 'dateFormat':
        setDateFormat(newValue)
        if (newValue !== user.settings.formats.dateFormat.toString()) {
          addId = true
        }
        break
      case 'numberFormat':
        setNumberFormat(newValue)
        if (
          newValue !==
          numberFormatOptionFromFormat(
            user.settings.formats.numberFormat,
          ).toString()
        ) {
          addId = true
        }
        break
      case 'partyFormat':
        setPartyFormat(partyFormatFromString(newValue, user.settings.language)!)

        if (
          newValue !==
          getReadableValueFromPartyFormatForReport(
            user.settings.formats.partyFormat,
            AvailableLanguages.english,
          )
        ) {
          addId = true
        }
        break
      case 'opposingPartyFormat':
        setOpposingPartyFormat(
          partyFormatFromString(newValue, user.settings.language)!,
        )
        if (
          newValue !==
          getReadableValueFromPartyFormatForReport(
            user.settings.formats.opposingPartyFormat,
            AvailableLanguages.english,
          )
        ) {
          addId = true
        }
        break
      case 'defaultCurrency':
        setDefaultCurrency(currencyFromString(newValue))
        if (newValue !== user.settings.formats.currency.toString()) {
          addId = true
        }
        break
    }

    if (addId && !showSaveButtons.includes(id)) {
      setShowSaveButtons([...showSaveButtons, id])
    } else if (!addId && showSaveButtons.includes(id)) {
      let newSaveButtons = [...showSaveButtons]
      newSaveButtons.splice(newSaveButtons.indexOf(id))

      setShowSaveButtons([...newSaveButtons])
    }
  }

  const onSave = async (id: string) => {
    if (loading !== '') {
      return
    }

    setLoading(id)

    let newSettings: UserSettings = deepCloneObject(user.settings)
    switch (id) {
      case 'dateFormat':
        newSettings.formats.dateFormat = dateFormatOptionFromString(dateFormat)
        break
      case 'numberFormat':
        newSettings.formats.numberFormat.thousandSeparator = numberFormat[1] as
          | ' '
          | ','
          | '.'
        newSettings.formats.numberFormat.decimalSeparator = numberFormat[9] as
          | ','
          | '.'
        break
      case 'partyFormat':
        newSettings.formats.partyFormat = partyFormat
        newSettings.formats.opposingPartyFormat = opposingPartyFormat
        if (partyFormat === opposingPartyFormat) {
          //error-85 chagng it in FormatComponent prop errorTextAttribute
          setPartiesErrorMessage(getText('error-85', user.settings))
          setLoading('')
          return
        }
        break
      case 'defaultCurrency':
        newSettings.formats.currency = defaultCurrency
        break
    }

    let success = true
    if (freemium.isFreemium) {
      saveFreemiumPreferences({
        checklist: user.checklist,
        settings: newSettings,
      })

      let newUser: User = deepCloneObject(user)
      newUser.settings = newSettings
      setUser(newUser)
    } else {
      const res = await saveUserSettings(newSettings)
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'updateMyInfo'),
      )

      success = !('errorCode' in res)
      if (success) {
        let newUser: User = deepCloneObject(user)
        newUser.settings = UserSettings.FromJson((res as AxiosResponse).data)
        setUser(newUser)
      }
    }

    if (success) {
      let newSaveButtons = [...showSaveButtons]
      newSaveButtons.splice(newSaveButtons.indexOf(id), 1)
      if (id === 'partyFormat') {
        newSaveButtons.splice(newSaveButtons.indexOf('opposingPartyFormat'), 1)
      }
      setShowSaveButtons([...newSaveButtons])
      setPartiesErrorMessage(undefined)

      setShowSaved(id)
      setTimeout(() => {
        setShowSaved('')
      }, 2500)

      //Mixpanel 102 (All)
      logActivity(freemium.isFreemium, `Changed ${id} in Preferences`)
    }

    setLoading('')
  }

  return (
    <div className="tool-component for-settings" id="accountDetailsComp">
      <h2
        className="tool-component-title"
        id="preferencesTitle"
        data-textattribute="title-178"
      >
        {getText('title-178', user.settings)}
      </h2>
      <FormatComponent
        titleText={getText('title-150', user.settings)}
        titleTextAttribute={'title-150'}
        ids={['dateFormat']}
        labelTextsArray={[getText('label-70', user.settings)]}
        labelTextsArrayAttribute={['label-70']}
        values={[dateFormat]}
        options={[valuesIn(DateFormatOption)]}
        onChange={onChange}
        hoverElement={hoverElement}
        setHoverElement={setHoverElement}
        infoButtonTextsArray={[getText('infoButton-42', user.settings)]}
        infoButtonTextsArrayAttribute={['infoButton-42']}
        showSaved={showSaved}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        loading={loading}
        onSave={onSave}
        showSaveButtons={showSaveButtons}
        descriptionText={getText('description-98', user.settings)}
        descriptionTextAttribute={'description-98'}
      />
      <FormatComponent
        titleText={getText('title-151', user.settings)}
        titleTextAttribute={'title-151'}
        ids={['numberFormat']}
        labelTextsArray={[getText('label-71', user.settings)]}
        labelTextsArrayAttribute={['label-71']}
        values={[numberFormat]}
        options={[valuesIn(NumberFormatOption)]}
        onChange={onChange}
        hoverElement={hoverElement}
        setHoverElement={setHoverElement}
        infoButtonTextsArray={[getText('infoButton-43', user.settings)]}
        infoButtonTextsArrayAttribute={['infoButton-43']}
        showSaved={showSaved}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        loading={loading}
        onSave={onSave}
        showSaveButtons={showSaveButtons}
        descriptionText={getText('description-99', user.settings)}
        descriptionTextAttribute={'description-99'}
      />
      {!freemium.isFreemium && (
        <FormatComponent
          titleText={getText('title-152', user.settings)}
          titleTextAttribute={'title-152'}
          ids={['partyFormat', 'opposingPartyFormat']}
          labelTextsArray={[
            getText('label-83', user.settings),
            getText('label-84', user.settings),
          ]}
          labelTextsArrayAttribute={['label-83', 'label-84']}
          values={
            user.settings.language === AvailableLanguages.norwegian
              ? [
                  NorwegianPartyFormat[partyFormat],
                  NorwegianPartyFormat[opposingPartyFormat],
                ]
              : user.settings.language === AvailableLanguages.swedish
              ? [
                  SwedishPartyFormat[partyFormat],
                  SwedishPartyFormat[opposingPartyFormat],
                ]
              : [PartyFormat[partyFormat], PartyFormat[opposingPartyFormat]]
          }
          options={
            user.settings.language === AvailableLanguages.norwegian
              ? [valuesIn(NorwegianPartyFormat), valuesIn(NorwegianPartyFormat)]
              : user.settings.language === AvailableLanguages.swedish
              ? [valuesIn(SwedishPartyFormat), valuesIn(SwedishPartyFormat)]
              : [valuesIn(PartyFormat), valuesIn(PartyFormat)]
          }
          onChange={onChange}
          hoverElement={hoverElement}
          setHoverElement={setHoverElement}
          infoButtonTextsArray={[
            getText('infoButton-44', user.settings),
            getText('infoButton-45', user.settings),
          ]}
          infoButtonTextsArrayAttribute={['infoButton-44', 'infoButton-45']}
          showSaved={showSaved}
          buttonText={getText('button-90', user.settings)}
          buttonTextAttribute={'button-90'}
          confirmationText={getText('button-91', user.settings)}
          confirmationTextAttribute={'button-91'}
          loading={loading}
          onSave={onSave}
          showSaveButtons={showSaveButtons}
          descriptionText={getText('description-100', user.settings)}
          descriptionTextAttribute={'description-100'}
          errorMessage={partiesErrorMessage}
          errorTextAttribute={`error-85`}
        />
      )}
      <FormatComponent
        titleText={'Default Currency'}
        titleTextAttribute={'title-000'}
        ids={['defaultCurrency']}
        labelTextsArray={[getText('label-62', user.settings)]}
        labelTextsArrayAttribute={['label-62']}
        values={[defaultCurrency]}
        options={[keysIn(Currency).filter((cur, index) => index % 2 === 0)]}
        onChange={onChange}
        hoverElement={hoverElement}
        setHoverElement={setHoverElement}
        infoButtonTextsArray={[getText('infoButton-46', user.settings)]}
        infoButtonTextsArrayAttribute={['infoButton-46']}
        showSaved={showSaved}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        loading={loading}
        onSave={onSave}
        showSaveButtons={showSaveButtons}
        descriptionText={getText('description-155', user.settings)}
        descriptionTextAttribute={'description-155'}
        asAutocomplete
        getDescriptions={[
          (currency) =>
            `(${getText(
              getCurrencyDescription(currencyFromString(currency)),
              user.settings,
            )})`,
        ]}
      />
      {freemium.isFreemium && <TermsOfUse />}
      <div className="tool-component-spacer" style={{ height: '13px' }}></div>
    </div>
  )
}

export default Preferences
