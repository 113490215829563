import { useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import {
  getStyleFromWhitelabel,
  snapshotHasLegalCosts,
} from '../../../../../../../../services/reportFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { calculateValuesForBestWorst } from '../../../../../../../../services/disputedAmountFunctions'
import {
  findOwnLegalCostsForOutcome,
  includedIncurredCosts,
  resultsNotZero,
} from '../../../../../../../../services/resultsFunctions'
import { getText } from '../../../../../../../../services/textFunctions'
import { reverseCase } from '../../../../../../../../services/reverseCaseFunctions'
import fitty from 'fitty'
import { useEffect } from 'react'
import useWindowSize from '../../../../../../../../customHooks/useWindowSize'
import IncludeOptionComponent from '../../IncludeOptionComponent'

type Props = {
  reportWhitelabel?: ReportWhitelabel
  resultsViewParty: 'client' | 'opposing'
  backendResults: ResultsObject
  setShowIncludeOption?: (
    showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined,
  ) => void
  visibleElements?: ExecutiveSummaryVisibleElements
  showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined
  handleIncludeExcludeElement: (
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) => void
}

export default function BestWorstScenario(props: Props) {
  const { width } = useWindowSize()
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const reversedSnapshot = reverseCase(scenarioSnapshot.currentSnapshot)

  useEffect(() => {
    fitty('.financialOutcomeText', {
      minSize: 14,
      maxSize: 20,
      multiLine: false,
    })
  }, [width])

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const resultsSnapshot =
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot
      : reversedSnapshot

  const fade =
    props.visibleElements &&
    !props.visibleElements[
      ExecutiveSummaryVisibleElementsEnum.newBestWorstScenario
    ]

  function getTextForProbability(type: 'best' | 'worst') {
    const notZeroResults = resultsNotZero(props.backendResults)

    if (
      props.backendResults.result.graph.length < 2 ||
      notZeroResults === false
    ) {
      return <></>
    }
    const {
      amountForBest,
      singleAmountForBest,
      probabilityForBest,
      amountForWorst,
      singleAmountForWorst,
      probabilityForWorst,
    } = calculateValuesForBestWorst(props.backendResults)
    if (type === 'best') {
      return (
        <p
          className={`scenarioProbabilityText ${fade ? 'fade' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
          id={`scenarioProbabilityText-${type}${
            props.visibleElements ? '-forReport' : ''
          }`}
        >
          <span
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
            )}
            className={`robotoNumbers ${fade ? 'fade' : ''}`}
          >
            {stringAndRoundBigNumbers(
              probabilityForBest,
              user.settings,
              resultsSettings.settings.roundedResults,
              true,
            )}
          </span>
          {getText('description-271', user.settings)}{' '}
          {amountForBest +
            includedIncurredCosts(
              'client',
              'both',
              resultsSettings.settings.includeIncurredCosts,
              resultsSnapshot,
            ) >=
          0
            ? `${getText('description-272', user.settings)} ${
                singleAmountForBest === false
                  ? `${getText('description-274', user.settings)} `
                  : ''
              }`
            : `${getText('description-273', user.settings)} ${
                singleAmountForBest === false
                  ? `${getText('description-275', user.settings)} `
                  : ''
              }`}
          <span
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
            )}
            className={`${fade ? 'fade' : ''} robotoNumbers`}
          >
            {stringAndRoundBigNumbers(
              amountForBest +
                includedIncurredCosts(
                  'client',
                  'both',
                  resultsSettings.settings.includeIncurredCosts,
                  resultsSnapshot,
                ),
              user.settings,
              resultsSettings.settings.roundedResults,
              true,
            )}{' '}
            {scenarioSnapshot.currentSnapshot.currency}
          </span>
        </p>
      )
    } else {
      return (
        <p
          className={`scenarioProbabilityText ${fade ? 'fade' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
          id={`scenarioProbabilityText-${type}${
            props.visibleElements ? '-forReport' : ''
          }`}
        >
          <span
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
            )}
            className={`robotoNumbers ${fade ? 'fade' : ''}`}
          >
            {stringAndRoundBigNumbers(
              probabilityForWorst,
              user.settings,
              resultsSettings.settings.roundedResults,
              true,
            )}
          </span>
          {getText('description-271', user.settings)}{' '}
          {amountForWorst +
            includedIncurredCosts(
              'client',
              'both',
              resultsSettings.settings.includeIncurredCosts,
              resultsSnapshot,
            ) >=
          0
            ? `${getText('description-272', user.settings)} ${
                singleAmountForWorst === false
                  ? `${getText('description-275', user.settings)} `
                  : ''
              }`
            : `${getText('description-273', user.settings)} ${
                singleAmountForWorst === false
                  ? `${getText('description-274', user.settings)} `
                  : ''
              }`}
          <span
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
            )}
            className={`${fade ? 'fade' : ''} robotoNumbers`}
          >
            {stringAndRoundBigNumbers(
              amountForWorst +
                includedIncurredCosts(
                  'client',
                  'both',
                  resultsSettings.settings.includeIncurredCosts,
                  resultsSnapshot,
                ),
              user.settings,
              resultsSettings.settings.roundedResults,
              true,
            )}{' '}
            {scenarioSnapshot.currentSnapshot.currency}
          </span>
        </p>
      )
    }
  }
  return (
    <div
      className={`bestWorstScenarioContainer ${
        props.visibleElements ? 'forReport' : ''
      }`}
      onMouseEnter={
        props.visibleElements
          ? () =>
              props.setShowIncludeOption!(
                ExecutiveSummaryVisibleElementsEnum.newBestWorstScenario,
              )
          : undefined
      }
      onMouseLeave={
        props.visibleElements
          ? () => props.setShowIncludeOption!(undefined)
          : undefined
      }
      data-openreplay-obscured
      id={`bestWorstScenarioContainer-${props.resultsViewParty}${
        props.visibleElements ? '-forReport' : ''
      }`}
    >
      {props.visibleElements &&
      props.showIncludeOption ===
        ExecutiveSummaryVisibleElementsEnum.newBestWorstScenario ? (
        <IncludeOptionComponent
          includeElement={
            props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.newBestWorstScenario
            ]
          }
          includeElementName={
            ExecutiveSummaryVisibleElementsEnum.newBestWorstScenario
          }
          handleIncludeExcludeElement={props.handleIncludeExcludeElement}
        />
      ) : null}
      <p
        className={`bestWorstScenarioTitle ${fade ? 'fade' : ''}`}
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.bold,
          'noRoboto',
        )}
        data-textattribute={`${
          props.resultsViewParty === 'client' ? 'title-266' : 'title-267'
        }`}
        data-openreplay-obscured
        id={`bestWorstScenarioTitle-${props.resultsViewParty}${
          props.visibleElements ? '-forReport' : ''
        }`}
      >
        {props.resultsViewParty === 'client'
          ? getText('title-266', user.settings, partiesFormat)
          : getText('title-267', user.settings, partiesFormat)}
      </p>
      <div
        className="bestWorstScenarioProbabilitiesContaier"
        data-openreplay-obscured
      >
        <div
          className="scenarioProbability"
          id={`bestScenarioProbabilty-${props.resultsViewParty}${
            props.visibleElements ? '-forReport' : ''
          }`}
        >
          {getTextForProbability('best')}
        </div>
        <div
          className="scenarioProbability"
          id={`worstScenarioProbabilty-${props.resultsViewParty}${
            props.visibleElements ? '-forReport' : ''
          }`}
        >
          {getTextForProbability('worst')}
        </div>
      </div>
      <div
        className={`bestWorstScenarioTableContainer ${fade ? 'fade' : ''}`}
        data-openreplay-obscured
      >
        <div
          className="bestWorstScenarioTableRow"
          id={`bestWorstScenarioTableRow-titles-${props.resultsViewParty}${
            props.visibleElements ? '-forReport' : ''
          }`}
        >
          <div className={`column1 ${fade ? 'fade' : ''}`}></div>
          <div
            className={`column2 ${fade ? 'fade' : ''}`}
            style={{
              ...getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionTitle,
                FontLabels.italic,
                'noRoboto',
              ),
              fontStyle: 'italic',
            }}
            data-textattribute="title-268"
            id={`bestWorstScenarioTable-titles-column2-${
              props.resultsViewParty
            }${props.visibleElements ? '-forReport' : ''}`}
          >
            {getText('title-268', user.settings)}
          </div>
          <div
            className={`column3 ${fade ? 'fade' : ''}`}
            style={{
              ...getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionTitle,
                FontLabels.italic,
                'noRoboto',
              ),
              fontStyle: 'italic',
            }}
            data-textattribute="title-269"
            id={`bestWorstScenarioTable-titles-column3-${
              props.resultsViewParty
            }${props.visibleElements ? '-forReport' : ''}`}
          >
            {getText('title-269', user.settings)}
          </div>
        </div>
        <div
          className="bestWorstScenarioTableRow"
          id="bestWorstScenarioTableRow-principalAmount"
        >
          <div
            className={`column1 ${fade ? 'fade' : ''}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            id={`bestWorstScenarioTable-principalAmount-column1-${
              props.resultsViewParty
            }${props.visibleElements ? '-forReport' : ''}`}
          >
            {getText('title-58', user.settings)}:
          </div>
          <div
            className={`column2 ${fade ? 'fade' : ''} robotoNumbers`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.regular,
              'roboto',
            )}
            id={`bestWorstScenarioTable-principalAmount-column2-${
              props.resultsViewParty
            }${props.visibleElements ? '-forReport' : ''}`}
          >
            {stringAndRoundBigNumbers(
              props.backendResults.minmax.max.amount_received,
              user.settings,
              resultsSettings.settings.roundedResults,
            )}
          </div>
          <div
            className={`column3 ${fade ? 'fade' : ''} robotoNumbers`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.regular,
              'roboto',
            )}
            id={`bestWorstScenarioTable-principalAmount-column3-${
              props.resultsViewParty
            }${props.visibleElements ? '-forReport' : ''}`}
          >
            {stringAndRoundBigNumbers(
              props.backendResults.minmax.min.amount_received,
              user.settings,
              resultsSettings.settings.roundedResults,
            )}
          </div>
        </div>
        {(props.backendResults.minmax.max.interest_received !== 0 ||
          props.backendResults.minmax.min.interest_received !== 0) && (
          <div
            className="bestWorstScenarioTableRow"
            id="bestWorstScenarioTableRow-interest"
          >
            <div
              className={`column1 ${fade ? 'fade' : ''}`}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
              )}
              data-textattribute="title-51"
              id={`bestWorstScenarioTable-interest-column1-${
                props.resultsViewParty
              }${props.visibleElements ? '-forReport' : ''}`}
            >
              {getText('title-51', user.settings)}:
            </div>
            <div
              className={`column2 ${fade ? 'fade' : ''} robotoNumbers`}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionTitle,
                FontLabels.regular,
                'roboto',
              )}
              id={`bestWorstScenarioTable-interest-column2-${
                props.resultsViewParty
              }${props.visibleElements ? '-forReport' : ''}`}
            >
              {stringAndRoundBigNumbers(
                props.backendResults.minmax.max.interest_received,
                user.settings,
                resultsSettings.settings.roundedResults,
              )}
            </div>
            <div
              className={`column3 ${fade ? 'fade' : ''} robotoNumbers`}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionTitle,
                FontLabels.regular,
                'roboto',
              )}
              id={`bestWorstScenarioTable-interest-column3-${
                props.resultsViewParty
              }${props.visibleElements ? '-forReport' : ''}`}
            >
              {stringAndRoundBigNumbers(
                props.backendResults.minmax.min.interest_received,
                user.settings,
                resultsSettings.settings.roundedResults,
              )}
            </div>
          </div>
        )}

        {snapshotHasLegalCosts(resultsSnapshot) ? (
          <>
            <div
              className="bestWorstScenarioTableRow"
              id="bestWorstScenarioTableRow-legalCosts"
            >
              <div
                className={`column1 ${fade ? 'fade' : ''}`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
                id={`bestWorstScenarioTable-legalCosts-column1-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
                data-textattribute={
                  resultsSettings.settings.includeIncurredCosts
                    ? 'title-17'
                    : 'title-280'
                }
              >
                {resultsSettings.settings.includeIncurredCosts
                  ? getText('title-17', user.settings)
                  : getText('title-280', user.settings)}
                :
              </div>
              <div
                className={`column2 ${fade ? 'fade' : ''} robotoNumbers`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.regular,
                  'roboto',
                )}
                id={`bestWorstScenarioTable-legalCosts-column2-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
                // It was 'first' incurred costs
              >
                {stringAndRoundBigNumbers(
                  findOwnLegalCostsForOutcome(
                    resultsSnapshot,
                    props.backendResults.minmax.max,
                  ) +
                    includedIncurredCosts(
                      'client',
                      'both',
                      resultsSettings.settings.includeIncurredCosts,
                      resultsSnapshot,
                    ),
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}
              </div>
              <div
                className={`column3 ${fade ? 'fade' : ''} robotoNumbers`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.regular,
                  'roboto',
                )}
                id={`bestWorstScenarioTable-legalCosts-column3-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
                // It was 'first' incurred costs
              >
                {stringAndRoundBigNumbers(
                  findOwnLegalCostsForOutcome(
                    resultsSnapshot,
                    props.backendResults.minmax.min,
                  ) +
                    includedIncurredCosts(
                      'client',
                      'both',
                      resultsSettings.settings.includeIncurredCosts,
                      resultsSnapshot,
                    ),
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}
              </div>
            </div>
            {props.backendResults.minmax.max.legal_fees_covered_by_other !==
              0 ||
            props.backendResults.minmax.min.legal_fees_covered_by_other !==
              0 ? (
              <div
                className="bestWorstScenarioTableRow"
                id="bestWorstScenarioTableRow-legalCostsAwarded"
              >
                <div
                  className={`column1 ${fade ? 'fade' : ''}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                  data-textattribute="title-270"
                  id={`bestWorstScenarioTable-legalCostsAwarded-column1-${
                    props.resultsViewParty
                  }${props.visibleElements ? '-forReport' : ''}`}
                >
                  {getText('title-270', user.settings)}:
                  <sup
                    style={{
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                      ),
                      position: 'absolute',
                      fontSize: '8px',
                    }}
                    className={`${fade ? 'fade' : ''}`}
                  >
                    3
                  </sup>
                </div>
                <div
                  className={`column2 ${fade ? 'fade' : ''} robotoNumbers`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionTitle,
                    FontLabels.regular,
                    'roboto',
                  )}
                  id={`bestWorstScenarioTable-legalCostsAwarded-column2-${
                    props.resultsViewParty
                  }${props.visibleElements ? '-forReport' : ''}`}
                >
                  {stringAndRoundBigNumbers(
                    props.backendResults.minmax.max.legal_fees_covered_by_other,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                  )}
                </div>
                <div
                  className={`column3 ${fade ? 'fade' : ''} robotoNumbers`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionTitle,
                    FontLabels.regular,
                    'roboto',
                  )}
                  id={`bestWorstScenarioTable-legalCostsAwarded-column3-${
                    props.resultsViewParty
                  }${props.visibleElements ? '-forReport' : ''}`}
                >
                  {stringAndRoundBigNumbers(
                    props.backendResults.minmax.min.legal_fees_covered_by_other,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                  )}
                </div>
              </div>
            ) : null}

            {props.backendResults.minmax.max.legal_fees_covered_by_insurance !==
              0 ||
            props.backendResults.minmax.min.legal_fees_covered_by_insurance !==
              0 ? (
              <>
                <div
                  className="bestWorstScenarioTableRow"
                  id="bestWorstScenarioTableRow-legalCostsInsurance"
                >
                  <div
                    className={`column1 ${fade ? 'fade' : ''}`}
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                    data-textattribute="title-104"
                    id={`bestWorstScenarioTable-legalCostsInsurance-column1-${
                      props.resultsViewParty
                    }${props.visibleElements ? '-forReport' : ''}`}
                  >
                    {getText('title-104', user.settings)}:
                  </div>
                  <div
                    className={`column2 ${fade ? 'fade' : ''} robotoNumbers`}
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionTitle,
                      FontLabels.regular,
                      'roboto',
                    )}
                    id={`bestWorstScenarioTable-legalCostsInsurance-column2-${
                      props.resultsViewParty
                    }${props.visibleElements ? '-forReport' : ''}`}
                  >
                    {stringAndRoundBigNumbers(
                      props.backendResults.minmax.max
                        .legal_fees_covered_by_insurance,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                    )}
                  </div>
                  <div
                    className={`column3 ${fade ? 'fade' : ''} robotoNumbers`}
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionTitle,
                      FontLabels.regular,
                      'roboto',
                    )}
                    id={`bestWorstScenarioTable-legalCostsInsurance-column3-${
                      props.resultsViewParty
                    }${props.visibleElements ? '-forReport' : ''}`}
                  >
                    {stringAndRoundBigNumbers(
                      props.backendResults.minmax.min
                        .legal_fees_covered_by_insurance,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        {props.backendResults.minmax.max.amount_received_out_of_court !== 0 ||
        props.backendResults.minmax.min.amount_received_out_of_court !== 0 ? (
          <>
            <div
              className="bestWorstScenarioTableRow"
              id="bestWorstScenarioTableRow-externalValue"
            >
              <div
                className={`column1 ${fade ? 'fade' : ''}`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
                data-textattribute="title-207"
                id={`bestWorstScenarioTable-externalValue-column1-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
              >
                {getText('title-207', user.settings)}:
              </div>
              <div
                className={`column2 ${fade ? 'fade' : ''} robotoNumbers`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.regular,
                  'roboto',
                )}
                id={`bestWorstScenarioTable-externalValue-column2-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
              >
                {stringAndRoundBigNumbers(
                  props.backendResults.minmax.max.amount_received_out_of_court,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}
              </div>

              <div
                className={`column3 ${fade ? 'fade' : ''} robotoNumbers`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.regular,
                  'roboto',
                )}
                id={`bestWorstScenarioTable-externalValue-column3-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
              >
                {stringAndRoundBigNumbers(
                  props.backendResults.minmax.min.amount_received_out_of_court,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}
              </div>
            </div>
          </>
        ) : null}
        {props.backendResults.minmax.max.enforcement_loss !== 0 ||
        props.backendResults.minmax.min.enforcement_loss !== 0 ? (
          <>
            <div
              className="bestWorstScenarioTableRow"
              id="bestWorstScenarioTableRow-enforcementRisk"
            >
              <div
                className={`column1 ${fade ? 'fade' : ''}`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
                id={`bestWorstScenarioTable-enforcementRisk-column1-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
                data-textattribute="title-65"
              >
                {getText('title-65', user.settings)}:
              </div>
              <div
                className={`column2 ${fade ? 'fade' : ''} robotoNumbers`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.regular,
                  'roboto',
                )}
                id={`bestWorstScenarioTable-enforcementRisk-column2-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
              >
                {stringAndRoundBigNumbers(
                  -props.backendResults.minmax.max.enforcement_loss,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}
              </div>
              <div
                className={`column3 ${fade ? 'fade' : ''} robotoNumbers`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.regular,
                  'roboto',
                )}
                id={`bestWorstScenarioTable-enforcementRisk-column3-${
                  props.resultsViewParty
                }${props.visibleElements ? '-forReport' : ''}`}
              >
                {stringAndRoundBigNumbers(
                  -props.backendResults.minmax.min.enforcement_loss,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}
              </div>
            </div>
          </>
        ) : null}
        <div
          className="bestWorstScenarioTableRow"
          id="bestWorstScenarioTableRow-financialOutcome"
        >
          <div className={`column1 ${fade ? 'fade' : ''}`}></div>
          <div className="column2 financialOutcome">
            <div className="financialOutcomeTextContainer">
              <p
                className={`financialOutcomeText ${
                  fade ? 'fade' : ''
                } robotoNumbersBold`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.bold,
                  'roboto',
                )}
                id={`financialOutcomeText-column2-${props.resultsViewParty}${
                  props.visibleElements ? '-forReport' : ''
                }`}
              >
                {stringAndRoundBigNumbers(
                  props.backendResults.minmax.max.financial_outcome +
                    includedIncurredCosts(
                      'client',
                      'both',
                      resultsSettings.settings.includeIncurredCosts,
                      resultsSnapshot,
                    ),
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}{' '}
                {scenarioSnapshot.currentSnapshot.currency}
              </p>
            </div>
          </div>
          <div className="column3 financialOutcome">
            <div className="financialOutcomeTextContainer">
              <p
                className={`financialOutcomeText ${
                  fade ? 'fade' : ''
                } robotoNumbersBold`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionTitle,
                  FontLabels.bold,
                  'roboto',
                )}
                id={`financialOutcomeText-column3-${props.resultsViewParty}${
                  props.visibleElements ? '-forReport' : ''
                }`}
              >
                {stringAndRoundBigNumbers(
                  props.backendResults.minmax.min.financial_outcome +
                    includedIncurredCosts(
                      'client',
                      'both',
                      resultsSettings.settings.includeIncurredCosts,
                      resultsSnapshot,
                    ),
                  user.settings,
                  resultsSettings.settings.roundedResults,
                )}{' '}
                {scenarioSnapshot.currentSnapshot.currency}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
