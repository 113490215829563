import {
  AmountSignum,
  ClaimType,
  Currency,
  LegalCostsSwitchButtonType,
} from '../models/enums'
import {
  OutcomeBreakdown,
  ResultsObject,
  ResultsSettings,
  TableResultObject,
  ValuesBreakdown,
} from '../models/generalTypes'
import { IndependentClaim } from '../models/independentClaim'
import { LegalCostsObject } from '../models/legalCostsModels/legalCosts'
import { QuantumScenario } from '../models/quantumScenario'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { TreeClaim } from '../models/treeModels/treeClaim'
import { EventId } from '../models/treeModels/treeTypes'
import { UserSettings } from '../models/userSettings'
import { roundToNearestCustom } from '../Modules/DisputeModules/AppFunctionsNew'
import { getComplexValueText, roundNumberTo } from './commonFunctions'
import { formattedNumToString } from './formatNum'
import { getText } from './textFunctions'

export function initializeDataForTable(
  outcomesList: any[],
  resultsSnapshot: ScenarioSnapshot,
  maxmin: boolean,
  includeIncurredCosts: boolean,
  userSettings: UserSettings,
) {
  /* Creates an array with all the results that it is used for sorting
    Indexes of array:
    0 - id
    1 - Description : it can be 0,1,2 or 3
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
    17 - name
    */
  const scenarioID = [
    getText('title-285', userSettings),
    getText('title-286', userSettings),
  ]

  var tempData: (string | number | OutcomeBreakdown[])[][] = []
  if (outcomesList.length > 0) {
    outcomesList.map((item: any, index: number) => {
      if (maxmin) {
        tempData.push([scenarioID[index]])
      } else {
        tempData.push([index])
      }
      //conditional for finding Description
      if (item.trial_count === 1 && !item.has_bankruptcy) {
        tempData[index].push(0)
      } else if (item.trial_count === 2 && !item.has_bankruptcy) {
        tempData[index].push(1)
      } else if (item.trial_count === 1 && item.has_bankruptcy) {
        tempData[index].push(2)
      } else if (item.trial_count === 2 && item.has_bankruptcy) {
        tempData[index].push(3)
      }
      tempData[index].push(item.probability_of_outcome)
      tempData[index].push(item.amount_received)
      tempData[index].push(item.interest_received)
      tempData[index].push(
        item.trial_count === 1
          ? -resultsSnapshot.legalCosts.clientLegalCosts?.firstProceedings
              .estimatedCosts! && resultsSnapshot.legalCosts.included
            ? -resultsSnapshot.legalCosts.clientLegalCosts?.firstProceedings
                .estimatedCosts! +
              includedIncurredCosts(
                'client',
                'both',
                includeIncurredCosts,
                resultsSnapshot,
              )
            : 0
          : resultsSnapshot.legalCosts.clientLegalCosts?.firstProceedings
              .estimatedCosts! &&
            resultsSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
              ?.estimatedCosts! &&
            resultsSnapshot.legalCosts.included
          ? -(
              resultsSnapshot.legalCosts.clientLegalCosts?.firstProceedings
                .estimatedCosts! +
              resultsSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
                ?.estimatedCosts!
            ) +
            includedIncurredCosts(
              'client',
              'both',
              includeIncurredCosts,
              resultsSnapshot,
            )
          : 0,
      )
      if (item.legal_fees_covered_by_other > 0) {
        tempData[index].push(item.legal_fees_covered_by_other)
      } else {
        tempData[index].push(0)
      }
      if (item.legal_fees_covered_by_other < 0) {
        tempData[index].push(item.legal_fees_covered_by_other)
      } else {
        tempData[index].push(0)
      }
      tempData[index].push(item.legal_fees_covered_by_insurance)
      tempData[index].push(item.amount_received_out_of_court)
      tempData[index].push(item.enforcement_loss)
      tempData[index].push(
        item.financial_outcome +
          includedIncurredCosts(
            'client',
            'both',
            includeIncurredCosts,
            resultsSnapshot,
          ),
      )
      let probabilityValue =
        (item.financial_outcome +
          includedIncurredCosts(
            'client',
            'both',
            includeIncurredCosts,
            resultsSnapshot,
          )) *
        item.probability_of_outcome

      tempData[index].push(probabilityValue)
      tempData[index].push(item.percentage_of_legal_fees)
      tempData[index].push(item.percentage_of_legal_fees_to_pay)
      tempData[index].push(item.percentage_of_total_claim)
      tempData[index].push(item.breakdown)
      tempData[index].push(item.name)

      return true
    })
  }
  return tempData
}

export function showLegalFees(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[5] !== 0 || item[6] !== 0 || item[7] !== 0 || item[8] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showInterest(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[4] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showInsurance(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[8] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showOutOfCourt(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[9] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showEnforcement(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[10] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}

export function calculateHeight(dataForTable: any) {
  let heigth = 285

  if (showInterest(dataForTable)) {
    heigth += 40
  }
  if (showLegalFees(dataForTable)) {
    heigth += 110
    if (showInsurance(dataForTable)) {
      heigth += 40
    }
  }
  if (showOutOfCourt(dataForTable)) {
    heigth += 40
  }
  if (showEnforcement(dataForTable)) {
    heigth += 40
  }
  return heigth
}

export function treeHasOnlyAmount(data: any) {
  if (
    data.findIndex((item: any) => {
      if (item[2] !== 0 || item[3] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return false
  }
  return true
}

export function treeHasOutOfCourt(data: any) {
  if (
    data.findIndex((item: any) => {
      if (item[3] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function checkIfTreeHasInterest(
  data: any,
  currentSnapshot: ScenarioSnapshot,
) {
  if (data === undefined) {
    return false
  }
  const hasFirstTrial = currentSnapshot.firstTrialDate !== undefined

  const hasSecondTrial =
    currentSnapshot.hasSecondTrial &&
    currentSnapshot.secondTrialDate !== undefined

  if (
    hasFirstTrial &&
    hasSecondTrial &&
    currentSnapshot.secondTrialDate! <= currentSnapshot.firstTrialDate!
  ) {
    return false
  }

  if (
    data.findIndex((item: any) => {
      if (item[2] !== 0 && item[2] !== undefined) {
        return true
      }
      return false
    }) >= 0 &&
    hasFirstTrial
  ) {
    return true
  }
  return false
}

export function treeTableWidth(data: any, currentSnapshot: ScenarioSnapshot) {
  let width = 305
  if (treeHasOutOfCourt(data)) {
    width += 180
  }
  if (checkIfTreeHasInterest(data, currentSnapshot)) {
    width += 110
  }
  if (treeHasOnlyAmount(data) === false) {
    width += 120
  }
  return width
}

export function claimOrEventHasInterest(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.hasInterest
  } else {
    return (claim as IndependentClaim).isInterest
  }
}

export function findClaimScenarioOrEventProbability(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.probability
  } else {
    return (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
      .probability
  }
}
export function findClaimScenarioOrEventAwardedAmount(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.awardedAmount !== undefined
      ? (claim as TreeClaim).treeDetails.events[breakdown.eventId].eventDetails
          .awardedAmount!
      : 0
  } else {
    return (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
      .amount !== undefined
      ? (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
          .amount!
      : 0
  }
}
export function findClaimScenarioOrEventOutOfCourtAmount(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
): number {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.outOfCourtAmount !== undefined
      ? (claim as TreeClaim).treeDetails.events[breakdown.eventId].eventDetails
          .outOfCourtAmount!
      : 0
  } else {
    return (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
      .outOfCourtAmount !== undefined
      ? (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
          .outOfCourtAmount!
      : 0
  }
}

export function findClaimOrEventInterestRate(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (
      (claim as TreeClaim).treeDetails.events[breakdown.eventId].eventDetails
        .interestRate * 100
    )
  } else {
    return (claim as IndependentClaim).interestRate!
  }
}

export function findClaimOrEventInterestDate(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.interestDate!
  } else {
    return (claim as IndependentClaim).interestDate!
  }
}

export function calculateClaimScenarioOrEventInterest(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
  trialDate: string,
) {
  let interestDate = findClaimOrEventInterestDate(claim, breakdown)
  const date1 = new Date(trialDate)
  const date2 = new Date(interestDate)

  const timeDiff = new Date(date1).getTime() - new Date(date2).getTime()
  const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))

  const interest = isNaN(
    (findClaimScenarioOrEventAwardedAmount(claim, breakdown) *
      daysDiff *
      findClaimOrEventInterestRate(claim, breakdown)) /
      100 /
      365,
  )
    ? 0
    : (findClaimScenarioOrEventAwardedAmount(claim, breakdown) *
        daysDiff *
        findClaimOrEventInterestRate(claim, breakdown)) /
      100 /
      365

  return interest
}

export function atLeastOneClaimHasInterest(snapshot: ScenarioSnapshot) {
  for (let claim of snapshot.claims) {
    if (claim.type === ClaimType.tree) {
      let treeEvents = (claim as TreeClaim).treeDetails.events
      for (let event of Object.keys(treeEvents)) {
        if (
          treeEvents[event as EventId].eventDetails.hasInterest &&
          treeEvents[event as EventId].eventDetails.interestDate !==
            undefined &&
          treeEvents[event as EventId].eventDetails.interestRate > 0
        ) {
          return true
        }
      }
    } else {
      if (
        (claim as IndependentClaim).isInterest &&
        (claim as IndependentClaim).interestDate !== undefined &&
        (claim as IndependentClaim).interestRate! > 0
      ) {
        return true
      }
    }
  }
  return false
}

export function atLeastOneTreeHasReducedAmounts(snapshot: ScenarioSnapshot) {
  let trees = snapshot.claims.filter((c) => c.type === ClaimType.tree)

  if (trees.length === 0) {
    return false
  }

  let treeEvents = trees
    .map((c) => (c as TreeClaim).treeDetails.events)
    .reduce((a, b) => ({ ...a, ...b }))

  for (let event of Object.values(treeEvents)) {
    if (event.eventDetails.reducedAwardedAmount !== 0) {
      return true
    }
  }

  return false
}

export function atLeastOneTreeHasExternalValue(snapshot: ScenarioSnapshot) {
  let trees = snapshot.claims.filter((c) => c.type === ClaimType.tree)

  if (trees.length === 0) {
    return false
  }

  let treeEvents = trees
    .map((c) => (c as TreeClaim).treeDetails.events)
    .reduce((a, b) => ({ ...a, ...b }))

  for (let event of Object.values(treeEvents)) {
    if (event.eventDetails.outOfCourtAmountSignum !== AmountSignum.off) {
      return true
    }
  }

  return false
}

export function calculateGetPayArray(
  resultsFromBackend: ResultsObject,
  includeIncurredCosts: boolean,
  resultsSnapshot: ScenarioSnapshot,
  roundedResults: boolean,
) {
  if (resultsFromBackend.result.graph.length <= 8) {
    const mergedResults = new Map<number, number>()

    for (let outcome of resultsFromBackend.table) {
      const financialOutcome = roundedResults
        ? roundToNearestCustom(
            outcome.financial_outcome +
              includedIncurredCosts(
                'client',
                'both',
                includeIncurredCosts,
                resultsSnapshot,
              ),
          )
        : outcome.financial_outcome +
          includedIncurredCosts(
            'client',
            'both',
            includeIncurredCosts,
            resultsSnapshot,
          )
      const probability = outcome.probability_of_outcome

      if (mergedResults.has(financialOutcome)) {
        mergedResults.set(
          financialOutcome,
          mergedResults.get(financialOutcome)! + probability,
        )
      } else {
        mergedResults.set(financialOutcome, probability)
      }
    }

    const result = Array.from(mergedResults.entries()).map(
      ([financialOutcome, probability]) => [financialOutcome, probability],
    )

    result.sort((a, b) => b[0] - a[0])
    return result
  }
  const maxAmount =
    resultsFromBackend.minmax.max.financial_outcome +
    includedIncurredCosts(
      'client',
      'both',
      includeIncurredCosts,
      resultsSnapshot,
    )
  const minAmount =
    resultsFromBackend.minmax.min.financial_outcome +
    includedIncurredCosts(
      'client',
      'both',
      includeIncurredCosts,
      resultsSnapshot,
    )
  const numberOfSegments = 5

  let limitsArray = Array.from(
    { length: numberOfSegments + 2 },
    (_, i) =>
      maxAmount - (i * (maxAmount - minAmount)) / (numberOfSegments + 1),
  )

  // Exclude the first (maxAmount) and last (minAmount) points
  limitsArray = limitsArray.slice(1, -1)

  // Ensure 0 is included and still exclude the min and max
  if (!limitsArray.includes(0)) {
    limitsArray.push(0)
  }

  let getPayArray: [number, number][] = []
  const financialOutcomeSortedArray: number[] =
    resultsFromBackend.result.graph.map(
      (item: number[]) =>
        item[0] +
        includedIncurredCosts(
          'client',
          'both',
          includeIncurredCosts,
          resultsSnapshot,
        ),
    )

  for (const limit of limitsArray) {
    if (limit < 0) {
      const payArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value < limit ? index : -1))
        .filter((index) => index !== -1)

      const index: number =
        payArray.length > 0 && payArray[0] - 1 >= 0 ? payArray[0] - 1 : -1
      const limitProbability: number =
        index > -1 ? 1 - resultsFromBackend.result.graph[index][1] : 0

      if (limitProbability !== 0) {
        getPayArray.push([limit, limitProbability])
      }
    } else if (limit === 0) {
      // Probability of paying more than 0
      const payArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value < limit ? index : -1))
        .filter((index) => index !== -1)

      const index: number =
        payArray.length > 0 && payArray[0] - 1 >= 0 ? payArray[0] - 1 : -1

      let limitProbability: number =
        index > -1
          ? 1 - resultsFromBackend.result.graph[index][1]
          : payArray[0] === 0
          ? 1
          : 0
      if (limitProbability !== 0) {
        getPayArray.push([-0.00001234567, limitProbability])
      }
      // Probability of winning 0 or more
      const getArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value >= limit ? index : -1))
        .filter((index) => index !== -1)

      const winIndex: number =
        getArray.length > 0 && getArray[getArray.length - 1] >= 0
          ? getArray[getArray.length - 1]
          : -1
      limitProbability =
        winIndex > -1 ? resultsFromBackend.result.graph[winIndex][1] : 0
      if (limitProbability !== 0) {
        getPayArray.push([0, limitProbability])
      }
    } else if (limit > 0) {
      const getArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value > limit ? index : -1))
        .filter((index) => index !== -1)

      const index: number =
        getArray.length > 0 && getArray[getArray.length - 1] >= 0
          ? getArray[getArray.length - 1]
          : -1
      const limitProbability: number =
        index > -1 ? resultsFromBackend.result.graph[index][1] : 0
      if (limitProbability !== 0) {
        getPayArray.push([limit, limitProbability])
      }
    }
  }
  getPayArray.sort((a, b) => b[0] - a[0])

  const indexesToRemove: number[] = []
  for (let i = 0; i < getPayArray.length - 1; i++) {
    if (getPayArray[i][1] === getPayArray[i + 1][1]) {
      if (
        getPayArray[i][0] !== 0 &&
        getPayArray[i][0] !== -0.00001234567 &&
        Math.abs(getPayArray[i][0]) < Math.abs(getPayArray[i + 1][0])
      ) {
        indexesToRemove.push(i)
      }
      if (
        getPayArray[i + 1][0] !== 0 &&
        getPayArray[i + 1][0] !== -0.00001234567 &&
        Math.abs(getPayArray[i + 1][0]) < Math.abs(getPayArray[i][0])
      ) {
        indexesToRemove.push(i + 1)
      }
    }
  }

  getPayArray = getPayArray.filter(
    (x, index) => !indexesToRemove.includes(index),
  )

  return getPayArray
}

export function getDistanceNumberForSelectionGraph(
  requestedNumber: 'topLeftOffset',
  condition:
    | 'onlyAmount'
    | 'onlyInterest'
    | 'onlyExternal'
    | 'externalInterest'
    | 'results'
    | 'report',
) {
  if (requestedNumber === 'topLeftOffset') {
    if (condition === 'onlyAmount') {
      return [69, 69]
    } else if (condition === 'onlyInterest') {
      return [69, 68]
    } else if (condition === 'onlyExternal') {
      return [76, 78]
    } else if (condition === 'externalInterest') {
      return [88, 95]
    } else if (condition === 'results') {
      return [60, 120]
    } else if (condition === 'report') {
      return [20, 121]
    }
  }
  return [60, 120]
}

export function getHighestValue(array: number[][], value: number): number {
  return Math.max(...array.filter((el) => el[0] === value).map((el) => el[1]))
}

export function getLowestValue(array: number[][], value: number): number {
  return Math.min(...array.filter((el) => el[0] === value).map((el) => el[1]))
}

export function resultsAreNotZero(data: number[][]): boolean {
  for (let result of data) {
    if (result[0] !== 0) {
      return true
    }
  }
  return false
}

export function snapshotHasExternal(snapshot: ScenarioSnapshot) {
  for (let claim of snapshot.claims) {
    if (claim.type !== ClaimType.tree) {
      if (
        claim.hasOutOfCourt &&
        claim.quantumScenarios.filter(
          (quantumScenario: QuantumScenario) =>
            quantumScenario.outOfCourtAmount! > 0,
        ).length > 0
      ) {
        return true
      }
    }
  }
  return atLeastOneTreeHasExternalValue(snapshot)
}

export function findOwnLegalCostsForOutcome(
  snapshot: ScenarioSnapshot,
  outcome: TableResultObject,
): number {
  if (outcome.trial_count === 1) {
    return -snapshot.legalCosts.clientLegalCosts.firstProceedings
      .estimatedCosts!
  } else {
    return (
      -snapshot.legalCosts.clientLegalCosts.additionalProceedings!
        .estimatedCosts! -
      snapshot.legalCosts.clientLegalCosts.firstProceedings.estimatedCosts!
    )
  }
}

export function resultsNotZero(results: ResultsObject) {
  for (let graph of results.result.graph) {
    if (graph[0] !== 0) {
      return true
    }
  }
  return false
}

export function defaultValuesBreakdown() {
  let valuesBreakdown = {
    valueOfClientClaims: undefined,
    valueOfClientInterest: undefined,
    valueOfExternalValues: undefined,
    valueOfLegalCosts: undefined,
    valueOfLegalCostsCoveredByInsurance: undefined,
    valueOfLegalCostsToBePaidByOpposingParty: undefined,
    valueOfLegalCostsToBePaidToOpposingParty: undefined,
    valueOfOpposingPartyClaims: undefined,
    valueOfOpposingPartyInterest: undefined,
    valueOfUnsuccessfulEnforcement: undefined,
  }

  return valuesBreakdown
}

export function findValueBreakdown(
  valuesBreakdownFromResults: {
    value_of_client_claims: number
    value_of_client_interest: number
    value_of_external_values: number
    value_of_legal_costs: number
    value_of_legal_costs_covered_by_insurance: number
    value_of_legal_costs_to_be_paid_by_opposing_party: number
    value_of_legal_costs_to_be_paid_to_opposing_party: number
    value_of_opposing_party_claims: number
    value_of_opposing_party_interest: number
    value_of_unsuccessful_enforcement: number
  },
  type: 'positive' | 'negative',
): ValuesBreakdown {
  let tempValuesBreakdown: ValuesBreakdown = defaultValuesBreakdown()

  if (type === 'positive') {
    // 1
    if (valuesBreakdownFromResults.value_of_client_claims > 0) {
      tempValuesBreakdown.valueOfClientClaims =
        valuesBreakdownFromResults.value_of_client_claims
    }
    // 2
    if (valuesBreakdownFromResults.value_of_client_interest > 0) {
      tempValuesBreakdown.valueOfClientInterest =
        valuesBreakdownFromResults.value_of_client_interest
    }
    // 3
    if (
      valuesBreakdownFromResults.value_of_legal_costs_to_be_paid_by_opposing_party >
      0
    ) {
      tempValuesBreakdown.valueOfLegalCostsToBePaidByOpposingParty =
        valuesBreakdownFromResults.value_of_legal_costs_to_be_paid_by_opposing_party
    }
    // 4
    if (
      valuesBreakdownFromResults.value_of_legal_costs_covered_by_insurance > 0
    ) {
      tempValuesBreakdown.valueOfLegalCostsCoveredByInsurance =
        valuesBreakdownFromResults.value_of_legal_costs_covered_by_insurance
    }
    // 9
    if (valuesBreakdownFromResults.value_of_external_values > 0) {
      tempValuesBreakdown.valueOfExternalValues =
        valuesBreakdownFromResults.value_of_external_values
    }
  } else {
    // 5
    if (valuesBreakdownFromResults.value_of_opposing_party_claims < 0) {
      tempValuesBreakdown.valueOfOpposingPartyClaims =
        valuesBreakdownFromResults.value_of_opposing_party_claims
    }
    // 6
    if (valuesBreakdownFromResults.value_of_opposing_party_interest < 0) {
      tempValuesBreakdown.valueOfOpposingPartyInterest =
        valuesBreakdownFromResults.value_of_opposing_party_interest
    }
    // 7
    if (
      valuesBreakdownFromResults.value_of_legal_costs_to_be_paid_to_opposing_party <
      0
    ) {
      tempValuesBreakdown.valueOfLegalCostsToBePaidToOpposingParty =
        valuesBreakdownFromResults.value_of_legal_costs_to_be_paid_to_opposing_party
    }
    // 8

    if (valuesBreakdownFromResults.value_of_unsuccessful_enforcement < 0) {
      tempValuesBreakdown.valueOfUnsuccessfulEnforcement =
        valuesBreakdownFromResults.value_of_unsuccessful_enforcement
    }
    // 9
    if (valuesBreakdownFromResults.value_of_external_values < 0) {
      tempValuesBreakdown.valueOfExternalValues =
        valuesBreakdownFromResults.value_of_external_values
    }
    // 10
    if (valuesBreakdownFromResults.value_of_legal_costs < 0) {
      tempValuesBreakdown.valueOfLegalCosts =
        valuesBreakdownFromResults.value_of_legal_costs
    }
  }
  return tempValuesBreakdown
}

export function shouldShowValueBreakdown(
  valuesBreakdown: ValuesBreakdown,
  type: 'positive' | 'negative',
) {
  if (type === 'positive') {
    if (
      // 1 2 3 4 9
      (valuesBreakdown.valueOfClientClaims &&
        valuesBreakdown.valueOfClientClaims > 0) ||
      (valuesBreakdown.valueOfClientInterest &&
        valuesBreakdown.valueOfClientInterest > 0) ||
      (valuesBreakdown.valueOfLegalCostsToBePaidByOpposingParty &&
        valuesBreakdown.valueOfLegalCostsToBePaidByOpposingParty > 0) ||
      (valuesBreakdown.valueOfLegalCostsCoveredByInsurance &&
        valuesBreakdown.valueOfLegalCostsCoveredByInsurance > 0) ||
      (valuesBreakdown.valueOfExternalValues &&
        valuesBreakdown.valueOfExternalValues > 0)
    ) {
      return true
    }
  } else {
    if (
      // 5 6 7 8 9 10
      (valuesBreakdown.valueOfOpposingPartyClaims &&
        valuesBreakdown.valueOfOpposingPartyClaims < 0) ||
      (valuesBreakdown.valueOfOpposingPartyInterest &&
        valuesBreakdown.valueOfOpposingPartyInterest < 0) ||
      (valuesBreakdown.valueOfLegalCostsToBePaidToOpposingParty &&
        valuesBreakdown.valueOfLegalCostsToBePaidToOpposingParty < 0) ||
      (valuesBreakdown.valueOfUnsuccessfulEnforcement &&
        valuesBreakdown.valueOfUnsuccessfulEnforcement < 0) ||
      (valuesBreakdown.valueOfExternalValues &&
        valuesBreakdown.valueOfExternalValues < 0) ||
      (valuesBreakdown.valueOfLegalCosts &&
        valuesBreakdown.valueOfLegalCosts < 0)
    ) {
      return true
    }
  }
  return false
}

export function findHighestValueOfValuesBreakdown(valuesBreakdownFromResults: {
  value_of_client_claims: number
  value_of_client_interest: number
  value_of_external_values: number
  value_of_legal_costs: number
  value_of_legal_costs_covered_by_insurance: number
  value_of_legal_costs_to_be_paid_by_opposing_party: number
  value_of_legal_costs_to_be_paid_to_opposing_party: number
  value_of_opposing_party_claims: number
  value_of_opposing_party_interest: number
  value_of_unsuccessful_enforcement: number
}) {
  let highestValue = 0
  for (const key of Object.keys(valuesBreakdownFromResults)) {
    if (
      Math.abs(
        valuesBreakdownFromResults[
          key as keyof typeof valuesBreakdownFromResults
        ],
      ) > highestValue
    ) {
      highestValue = Math.abs(
        valuesBreakdownFromResults[
          key as keyof typeof valuesBreakdownFromResults
        ],
      )
    }
  }
  return highestValue
}

export function checkIfResultsAreOutdated(results: ResultsObject) {
  if (results.reversed === undefined) {
    return true
  }
  if (results.values_breakdown === undefined) {
    return true
  }
  return false
}

export function calculateIncurredCosts(
  legalCosts: LegalCostsObject,
  hasSecondTrial: boolean,
) {
  let first_Client_ProceedingsIncurredLegalCosts = 0
  let additional_Client_ProceedingsIncurredLegalCosts = 0
  let first_OpposingParty_ProceedingsIncurredLegalCosts = 0
  let additional_OpposingParty_ProceedingsIncurredLegalCosts = 0

  if (legalCosts.clientLegalCosts.firstProceedings.incurredCosts.value) {
    if (
      legalCosts.clientLegalCosts.firstProceedings.incurredCosts.type ===
      LegalCostsSwitchButtonType.currency
    ) {
      first_Client_ProceedingsIncurredLegalCosts +=
        legalCosts.clientLegalCosts.firstProceedings.incurredCosts.value
    } else {
      first_Client_ProceedingsIncurredLegalCosts +=
        (legalCosts.clientLegalCosts.firstProceedings.incurredCosts.value *
          legalCosts.clientLegalCosts.firstProceedings.estimatedCosts!) /
        100
    }
  }

  if (hasSecondTrial && legalCosts.clientLegalCosts.additionalProceedings) {
    if (legalCosts.clientLegalCosts.additionalProceedings.incurredCosts.value) {
      if (
        legalCosts.clientLegalCosts.additionalProceedings.incurredCosts.type ===
        LegalCostsSwitchButtonType.currency
      ) {
        additional_Client_ProceedingsIncurredLegalCosts +=
          legalCosts.clientLegalCosts.additionalProceedings.incurredCosts.value
      } else {
        additional_Client_ProceedingsIncurredLegalCosts +=
          (legalCosts.clientLegalCosts.additionalProceedings.incurredCosts
            .value *
            legalCosts.clientLegalCosts.additionalProceedings.estimatedCosts!) /
          100
      }
    }
  }

  if (legalCosts.opposingPartyLegalCosts.firstProceedings.incurredCosts.value) {
    if (
      legalCosts.opposingPartyLegalCosts.firstProceedings.incurredCosts.type ===
      LegalCostsSwitchButtonType.currency
    ) {
      first_OpposingParty_ProceedingsIncurredLegalCosts +=
        legalCosts.opposingPartyLegalCosts.firstProceedings.incurredCosts.value
    } else {
      first_OpposingParty_ProceedingsIncurredLegalCosts +=
        (legalCosts.opposingPartyLegalCosts.firstProceedings.incurredCosts
          .value *
          legalCosts.opposingPartyLegalCosts.firstProceedings.estimatedCosts!) /
        100
    }
  }

  if (
    hasSecondTrial &&
    legalCosts.opposingPartyLegalCosts.additionalProceedings
  ) {
    if (
      legalCosts.opposingPartyLegalCosts.additionalProceedings.incurredCosts
        .value
    ) {
      if (
        legalCosts.opposingPartyLegalCosts.additionalProceedings.incurredCosts
          .type === LegalCostsSwitchButtonType.currency
      ) {
        additional_OpposingParty_ProceedingsIncurredLegalCosts +=
          legalCosts.opposingPartyLegalCosts.additionalProceedings.incurredCosts
            .value
      } else {
        additional_OpposingParty_ProceedingsIncurredLegalCosts +=
          (legalCosts.opposingPartyLegalCosts.additionalProceedings
            .incurredCosts.value *
            legalCosts.opposingPartyLegalCosts.additionalProceedings
              .estimatedCosts!) /
          100
      }
    }
  }
  let total_Client_IncurredLegalCosts =
    first_Client_ProceedingsIncurredLegalCosts +
    additional_Client_ProceedingsIncurredLegalCosts
  let total_OpposingParty_IncurredLegalCosts =
    first_OpposingParty_ProceedingsIncurredLegalCosts +
    additional_OpposingParty_ProceedingsIncurredLegalCosts
  return {
    first_Client_ProceedingsIncurredLegalCosts,
    additional_Client_ProceedingsIncurredLegalCosts,
    total_Client_IncurredLegalCosts,
    first_OpposingParty_ProceedingsIncurredLegalCosts,
    additional_OpposingParty_ProceedingsIncurredLegalCosts,
    total_OpposingParty_IncurredLegalCosts,
  }
}

export function includedIncurredCosts(
  party: 'client' | 'opposing',
  trial: 'first' | 'second' | 'both',
  includeIncurredCosts: boolean,
  snapshot: ScenarioSnapshot,
  includeInsurance?: 'includeInsurance',
) {
  if (includeIncurredCosts) return 0

  const {
    first_Client_ProceedingsIncurredLegalCosts,
    additional_Client_ProceedingsIncurredLegalCosts,
    total_Client_IncurredLegalCosts,
    first_OpposingParty_ProceedingsIncurredLegalCosts,
    additional_OpposingParty_ProceedingsIncurredLegalCosts,
    total_OpposingParty_IncurredLegalCosts,
  } = calculateIncurredCosts(snapshot.legalCosts, true)

  const map: Record<string, number> = {
    'first-client': first_Client_ProceedingsIncurredLegalCosts,
    'first-opposing': first_OpposingParty_ProceedingsIncurredLegalCosts,
    'second-client': additional_Client_ProceedingsIncurredLegalCosts,
    'second-opposing': additional_OpposingParty_ProceedingsIncurredLegalCosts,
    'both-client': total_Client_IncurredLegalCosts,
    'both-opposing': total_OpposingParty_IncurredLegalCosts,
  }

  let insuranceValue =
    includeInsurance === 'includeInsurance'
      ? calculateInsurance(map[`${trial}-${party}`], snapshot)
      : 0

  return map[`${trial}-${party}`] - insuranceValue
}

export function calculateInsurance(
  legalCostsForInsurance: number,
  snapshot: ScenarioSnapshot,
): number {
  if (snapshot.hasInsurance === false) return 0

  let insuranceValue = 0.0
  let excessFee = 0.0
  let amountForExcessFeeCalculation = legalCostsForInsurance

  // Set the excess fee as the biggest between insurance_minimum_excess_fee and insurance_excess_fee_percentage*amount_for_excess_fee_calculation
  excessFee = Math.max(
    snapshot.minimumExcessFee!,
    (snapshot.insuranceExcessPercentage! * amountForExcessFeeCalculation) / 100,
  )

  // Conditionals for insurance:
  // 1. if the legal fees are equal or less than the excess fee, the insurance pays nothing
  // 2. if the legal fees are equal or less than the maximum insurance coverage, plus the excess fee, the insurance pays the legal fees minus the excess fee
  // 3. if the legal fees are more than the maximum insurance coverage, plus the excess fee, the insurance pays the maximum insurance coverage
  if (legalCostsForInsurance <= excessFee) {
    insuranceValue = 0
  } else if (legalCostsForInsurance <= snapshot.maxInsurance! + excessFee) {
    insuranceValue = legalCostsForInsurance - excessFee
  } else {
    insuranceValue = snapshot.maxInsurance!
  }

  return insuranceValue
}

export const getLegalCostsSingleValue = (
  value: number | string,
  currency: Currency,
  userSettings: UserSettings,
  isPercentage?: boolean,
) => {
  if (typeof value === 'string') {
    return `${value} ${isPercentage ? '%' : currency}`
  }

  return `${formattedNumToString(roundNumberTo(value, 3), userSettings)}${
    isPercentage ? '%' : ` ${currency}`
  }`
}

export function getSubtitleForGetPayGraph(
  resultsFromBackend: ResultsObject,
  resultsSnapshot: ScenarioSnapshot,
  userSettings: UserSettings,
  resultsSettings: ResultsSettings,
) {
  const fewResults = resultsFromBackend.result.graph.length <= 8
  const firstSentence = fewResults
    ? getText('description-286', userSettings)
    : getText('description-249', userSettings)
  return `${firstSentence} ${getComplexValueText(
    resultsSnapshot,
    userSettings,
    resultsSettings.settings.includeIncurredCosts,
    true,
  )}`
}
