import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { User } from '../../../../../../../../../models/user'
import { ScenarioSnapshot } from '../../../../../../../../../models/scenarioSnapshot'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { getText } from '../../../../../../../../../services/textFunctions'
import { stringAndRoundBigNumbers } from '../../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import {
  ResultsObject,
  ResultsSettings,
} from '../../../../../../../../../models/generalTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'
import { getComplexValueText } from '../../../../../../../../../services/commonFunctions'
import { includedIncurredCosts } from '../../../../../../../../../services/resultsFunctions'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  user: User
  currentSnapshot: ScenarioSnapshot
  reportWhitelabel?: ReportWhitelabel
  resultsFromBackend: ResultsObject
  resultsSettings: ResultsSettings
  firstElement: boolean
}

export default function ExpectedValueDocument(props: Props) {
  const styles = StyleSheet.create({
    expectedValueContainer: {
      marginTop: props.firstElement ? 20 : 30,
    },
    expectedValueTitle: {
      fontSize: '11px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    expectedValueDescription: {
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
    },
    span: {
      fontFamily: 'Roboto-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
  })

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  return (
    <>
      <View style={styles.expectedValueContainer} wrap={false}>
        <Text style={styles.expectedValueTitle}>
          {getText('title-271', props.user.settings)}{' '}
          <Text style={styles.span}>
            {stringAndRoundBigNumbers(
              props.resultsFromBackend.value_of_claim +
                includedIncurredCosts(
                  'client',
                  'both',
                  props.resultsSettings.settings.includeIncurredCosts,
                  props.currentSnapshot,
                ),
              props.user.settings,
              props.resultsSettings.settings.roundedResults,
            )}
            {props.resultsFromBackend &&
            props.resultsFromBackend.model_used === 'statistical'
              ? '*'
              : ''}{' '}
            {props.currentSnapshot.currency}
          </Text>
        </Text>
        <Text style={{ ...styles.expectedValueDescription, marginTop: '2px' }}>
          {getText('description-261', props.user.settings, partiesFormat)}
        </Text>
        <Text style={styles.expectedValueDescription}>
          {getText('description-261b', props.user.settings)}
        </Text>
        <Text style={styles.expectedValueDescription}>
          {getComplexValueText(
            props.currentSnapshot,
            props.user.settings,
            props.resultsSettings.settings.includeIncurredCosts,
          )}
        </Text>
      </View>
    </>
  )
}
