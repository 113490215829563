import React, { useState, useEffect, useRef } from 'react'

import InfoClickComponent from '../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import HoverComponent from '../../../../Components/InfoHover/HoverComponent/HoverComponent'

import addSignImg from '../../../../resources/images/013-plusSignThinYellowBlue.svg'
import removeSignImg from '../../../../resources/images/014-xSignGrayWhite.svg'
import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import {
  DefaultSnapshotState,
  SnapshotSelectorObject,
} from '../../../../models/generalTypes'
import {
  AccessRole,
  LegalCostInputType,
  LegalCostsParty,
  LegalCostsProceedings,
  LegalCostsSwitchButtonType,
  UndoRedoType,
  VideoPostName,
} from '../../../../models/enums'
import { ScenarioSnapshot } from '../../../../models/scenarioSnapshot'
import {
  deepCloneObject,
  logActivity,
} from '../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../services/changeGlobalSnapshot'
import InputNum from '../../../../Components/Inputs/InputNum/InputNum'
import { userState } from '../../../../states/UserState'
import { getText } from '../../../../services/textFunctions'
import VideoInfoButton from '../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import PartyLegalCostsComponent from './PartyLegalCostsComponent'

import tickedImg from '../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../resources/images/018-untickedGrayBlue.svg'
import greyTickedImg from '../../../../resources/images/260-tickGrey.svg'

import { LegalCostsObject } from '../../../../models/legalCostsModels/legalCosts'
import { viewerState } from '../../../../states/ViewerState'
import { recoveryModeState } from '../../../../states/RecoveryModeState'
import { freemiumState } from '../../../../states/FreemiumState'
import PremiumFeatureIcon from '../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../../models/freemiumEnums'

type Props = {
  handleChangeKeyValue: (
    e: any,
    key: keyof ScenarioSnapshot,
    number?: boolean,
  ) => void
  setVideoPostName: (param: VideoPostName | undefined) => void
  errors: string[]
  ownRole: AccessRole
}

export default function LegalCosts(props: Props) {
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const currentSnapshot = scenarioSnapshot.currentSnapshot

  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature

  const user = useRecoilValue(userState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  function handleToggleHasInsurance(value: boolean) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'insuranceAddRemoveButton',
      undoRedoType: UndoRedoType.button,
      value: value,
      key: 'hasInsurance',
    }
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    if (!value) {
      tempScenarioSnapshot.currentSnapshot.maxInsurance = undefined
      tempScenarioSnapshot.currentSnapshot.minimumExcessFee = undefined
      tempScenarioSnapshot.currentSnapshot.insuranceExcessPercentage = undefined
    }

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleChangeLegalCostValue(
    e: any,
    party: LegalCostsParty,
    proceedings: LegalCostsProceedings,
    legalCostInputType: LegalCostInputType,
    value: number | LegalCostsSwitchButtonType | undefined,
  ) {
    let tempId = e.target.id
    let tempUndoRedoType = UndoRedoType.input
    let tempScenarioSnapshot: DefaultSnapshotState =
      deepCloneObject(scenarioSnapshot)
    let tempLegalCosts = tempScenarioSnapshot.currentSnapshot.legalCosts
    if (typeof value === 'number' || value === undefined) {
      if (legalCostInputType === LegalCostInputType.estimatedCosts) {
        tempLegalCosts[party]![proceedings]!.estimatedCosts = value
        //Mixpanel 171 (All)
        logActivity(
          freemium.isFreemium,
          `Changed ${legalCostInputType} for ${party} in ${proceedings}`,
        )
      } else {
        tempLegalCosts[party]![proceedings]![legalCostInputType]!.value = value
        //Mixpanel 171 (All)
        logActivity(
          freemium.isFreemium,
          `Changed ${legalCostInputType} for ${party} in ${proceedings}`,
        )
      }
    } else {
      tempUndoRedoType = UndoRedoType.button
      tempId = `legalCostsSwitchButtonContainer-${party}-${proceedings}-${legalCostInputType}`
      if (legalCostInputType !== LegalCostInputType.estimatedCosts) {
        tempLegalCosts[party]![proceedings]![legalCostInputType]!.type = value
        tempLegalCosts[party]![proceedings]![legalCostInputType]!.value =
          undefined
      }
      //Mixpanel 172 (All)
      logActivity(
        freemium.isFreemium,
        `Changed ${legalCostInputType} switchButton to ${value} for ${party} in ${proceedings}`,
      )
    }

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: tempId,
      undoRedoType: tempUndoRedoType,
      value: tempLegalCosts,
      key: 'legalCosts',
    }
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleToggleIncludeLegalCosts() {
    if (props.ownRole === AccessRole.VIEWER) {
      if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
      }
      return
    }

    const legalCosts = deepCloneObject(
      currentSnapshot.legalCosts,
    ) as LegalCostsObject
    legalCosts.included = !currentSnapshot.legalCosts.included

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `legalCostsAddRemoveButton`,
      undoRedoType: UndoRedoType.button,
      value: legalCosts,
      key: 'legalCosts',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    if (legalCosts.included === true) {
      //Mixpanel 31 (All)
      logActivity(freemium.isFreemium, 'Added-Included legal costs submenu')
    } else {
      //Mixpanel 32 (All)
      logActivity(freemium.isFreemium, 'Removed legal costs submenu')
    }
  }

  return (
    <div className="tool-component" id="legalFeesComp">
      <h2
        className="tool-component-title"
        id="legalCosts-title"
        data-textattribute="title-35"
      >
        {getText('title-35', user.settings)}
        <VideoInfoButton
          textAttribute="tooltip-19"
          postName={VideoPostName.legalCosts}
          setVideoPostName={props.setVideoPostName}
        />
      </h2>
      <div className="tool-component-add-button-container marginLeft1">
        {currentSnapshot.legalCosts.included === true ? (
          props.ownRole === AccessRole.VIEWER ? (
            <img
              src={greyTickedImg}
              alt="tickedGreyImg"
              className="tickImg disabled"
              id="legalCostsAddRemoveButton-on-viewer"
              tabIndex={30000}
              onClick={handleToggleIncludeLegalCosts}
            />
          ) : (
            <img
              src={tickedImg}
              alt="tickedImg"
              className="tickImg"
              id="legalCostsAddRemoveButton-on"
              tabIndex={30000}
              onClick={handleToggleIncludeLegalCosts}
            />
          )
        ) : (
          <img
            src={unTickedImg}
            alt="unTickedImg"
            className={
              props.ownRole === AccessRole.VIEWER
                ? 'tickImg disabled'
                : 'tickImg'
            }
            id="legalCostsAddRemoveButton-off"
            tabIndex={30000}
            onClick={handleToggleIncludeLegalCosts}
          />
        )}
        <p
          onClick={handleToggleIncludeLegalCosts}
          id="legalCostsAddRemoveText"
          data-textattribute="submenu-22"
          className={props.ownRole === AccessRole.VIEWER ? 'disabled' : ''}
        >
          {getText('submenu-22', user.settings)}
        </p>
      </div>

      {currentSnapshot.legalCosts.included ? (
        <>
          <PartyLegalCostsComponent
            handleChangeLegalCostValue={handleChangeLegalCostValue}
            party={LegalCostsParty.clientLegalCosts}
            errors={props.errors}
            ownRole={props.ownRole}
          />

          {currentSnapshot.hasInsurance ? (
            <>
              <div
                className="tool-component-submenu-container"
                style={{ maxWidth: '747px' }}
                id="insuranceAddRemoveButton"
              >
                <div
                  className="tool-component-remove-button-container"
                  onClick={() => {
                    handleToggleHasInsurance(false)
                    //Mixpanel 29
                    logActivity(false, 'Removed insurance submenu')
                  }}
                  tabIndex={10005}
                >
                  {props.ownRole !== AccessRole.VIEWER && (
                    <div className="addSignContainer">
                      <img src={removeSignImg} alt="addSign" />
                    </div>
                  )}
                  <p
                    id="insurance-submenuTitle-on"
                    data-textattribute="submenu-8"
                  >
                    {getText('submenu-8', user.settings)}
                  </p>
                </div>
                <div className="tool-component-submenu-input-container">
                  <InputNum
                    label={getText('label-36', user.settings)}
                    labelTextAttribute="label-36"
                    suffix={currentSnapshot.currency}
                    value={currentSnapshot.minimumExcessFee}
                    className="form-control robotoNumbers"
                    id="minimumExcessFee"
                    tabIndex={10006}
                    name={'minimumExcessFee'}
                    maxValue={99999999999}
                    maxNumberOfDecimals={3}
                    onChange={(e: any) =>
                      props.handleChangeKeyValue(e, 'minimumExcessFee')
                    }
                    width={280}
                    error={props.errors.includes('minimumExcessFee')}
                    errorMessage={getText('error-21', user.settings)}
                    errorTextAttribute="error-21"
                    readOnly={
                      props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                    }
                    disabled={
                      props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                    }
                  />
                  <InfoClickComponent
                    infoClickText={getText(
                      'infoButton-26',
                      user.settings,
                      partiesFormat,
                    )}
                    infoClickTextAttribute="infoButton-26"
                    idName="minimumExcessFee"
                    hoverElement={hoverElement}
                    setHoverElement={setHoverElement}
                  />
                </div>
                <div className="tool-component-submenu-input-container">
                  <InputNum
                    label={getText('label-37', user.settings)}
                    labelTextAttribute="label-37"
                    suffix="%"
                    value={currentSnapshot.insuranceExcessPercentage}
                    className="form-control robotoNumbers"
                    id="insuranceExcessPercentage"
                    tabIndex={10007}
                    name={'insuranceExcessPercentage'}
                    onChange={(e: any) =>
                      props.handleChangeKeyValue(e, 'insuranceExcessPercentage')
                    }
                    maxNumberOfDecimals={2}
                    maxValue={100}
                    width={280}
                    error={props.errors.includes('insuranceExcessPercentage')}
                    errorMessage={getText('error-25', user.settings)}
                    errorTextAttribute="error-25"
                    readOnly={
                      props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                    }
                    disabled={
                      props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                    }
                  />
                  <InfoClickComponent
                    infoClickText={getText(
                      'infoButton-27',
                      user.settings,
                      partiesFormat,
                    )}
                    infoClickTextAttribute="infoButton-27"
                    idName="insuranceExcessPercentage"
                    hoverElement={hoverElement}
                    setHoverElement={setHoverElement}
                  />
                </div>
                <div className="tool-component-submenu-input-container">
                  <InputNum
                    label={getText('label-38', user.settings)}
                    labelTextAttribute="label-38"
                    suffix={currentSnapshot.currency}
                    value={currentSnapshot.maxInsurance}
                    className="form-control robotoNumbers"
                    id="maxInsurance"
                    tabIndex={10008}
                    name={'maxInsurance'}
                    maxValue={99999999999}
                    maxNumberOfDecimals={3}
                    onChange={(e: any) =>
                      props.handleChangeKeyValue(e, 'maxInsurance')
                    }
                    width={280}
                    placeholderPadding="55px"
                    error={props.errors.includes('maxInsurance')}
                    errorMessage={getText('error-21', user.settings)}
                    errorTextAttribute="error-21"
                    readOnly={
                      props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                    }
                    disabled={
                      props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                    }
                  />
                  <InfoClickComponent
                    infoClickText={getText(
                      'infoButton-28',
                      user.settings,
                      partiesFormat,
                    )}
                    infoClickTextAttribute="infoButton-28"
                    idName="maxInsurance"
                    hoverElement={hoverElement}
                    setHoverElement={setHoverElement}
                  />
                </div>
              </div>
              <div
                className="tool-component-spacer"
                style={{ height: '18px' }}
              ></div>
            </>
          ) : (
            <>
              {props.ownRole !== AccessRole.VIEWER && (
                <>
                  <div
                    className="tool-component-add-button-container marginLeft2"
                    id="insuranceAddRemoveButton"
                    tabIndex={10005}
                    onClick={() => {
                      if (freemium.isFreemium) {
                        setFreemium({
                          ...freemium,
                          showMessage: FreemiumMessageType.General,
                        })
                      } else {
                        handleToggleHasInsurance(true)
                        //Mixpanel 28
                        logActivity(false, 'Added insurance submenu')
                      }
                    }}
                    onMouseEnter={() => {
                      secondaryHover.current = 'addInsurance'
                      setTimeout(() => {
                        if (secondaryHover.current === 'addInsurance') {
                          setHoverElement('addInsurance')
                        }
                      }, 1000)
                    }}
                    onMouseLeave={() => {
                      secondaryHover.current = 'close'
                      setHoverElement('')
                    }}
                  >
                    {hoverElement === 'addInsurance' ? (
                      <div
                        className="hoverComponentContainer"
                        style={{ top: '-10px', right: '-220px' }}
                      >
                        <HoverComponent
                          hoverText={getText(
                            'hover-23',
                            user.settings,
                            partiesFormat,
                          )}
                          textAttribute="hover-23"
                          id="addInsurance"
                        />
                      </div>
                    ) : null}
                    <div className="addSignContainer">
                      <img src={addSignImg} alt="addSign" />
                    </div>
                    <p
                      id="insurance-submenuTitle-off"
                      data-textattribute="submenu-9"
                    >
                      {getText('submenu-9', user.settings)}
                    </p>
                  </div>
                  {freemium.isFreemium && !currentSnapshot.hasInsurance && (
                    <PremiumFeatureIcon
                      targetId={'insuranceAddRemoveButton'}
                      forText
                    />
                  )}
                </>
              )}
              <div
                className="tool-component-spacer"
                style={{ height: '18px' }}
              ></div>
            </>
          )}

          <PartyLegalCostsComponent
            handleChangeLegalCostValue={handleChangeLegalCostValue}
            party={LegalCostsParty.opposingPartyLegalCosts}
            errors={props.errors}
            ownRole={props.ownRole}
          />
        </>
      ) : null}

      <div className="tool-component-spacer" style={{ height: '13px' }}></div>
    </div>
  )
}
