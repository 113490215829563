import {
  LegalCostsParty,
  LegalCostsProceedings,
  LegalCostsSwitchButtonType,
} from '../models/enums'
import { DefaultSnapshotState } from '../models/generalTypes'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { UserSettings } from '../models/userSettings'
import { roundNumberTo } from './commonFunctions'
import { formattedNumToString } from './formatNum'
import { includedIncurredCosts } from './resultsFunctions'

export function legalFeesZero(currentSnapshot: ScenarioSnapshot) {
  if (currentSnapshot.probSecondTrial && currentSnapshot.probSecondTrial > 0) {
    if (
      currentSnapshot.legalCosts.included === false ||
      (!currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .estimatedCosts &&
        !currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
          .estimatedCosts &&
        !currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
          ?.estimatedCosts &&
        !currentSnapshot.legalCosts.opposingPartyLegalCosts
          ?.additionalProceedings?.estimatedCosts)
    ) {
      return true
    }
  } else {
    if (
      currentSnapshot.legalCosts.included === false ||
      (!currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .estimatedCosts &&
        !currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
          .estimatedCosts)
    ) {
      return true
    }
  }
  return false
}

export function toggleAdditionalProceedingsInLegalCosts(
  tempScenarioSnapshot: DefaultSnapshotState,
  value: 'add' | 'remove',
): DefaultSnapshotState {
  if (value === 'add') {
    tempScenarioSnapshot.currentSnapshot.legalCosts.clientLegalCosts!.additionalProceedings =
      {
        estimatedCosts: undefined,
        recoverableCosts: {
          type: LegalCostsSwitchButtonType.percentage,
          value: 100,
        },
        incurredCosts: {
          type: LegalCostsSwitchButtonType.currency,
          value: 0,
        },
      }
    tempScenarioSnapshot.currentSnapshot.legalCosts.opposingPartyLegalCosts!.additionalProceedings =
      {
        estimatedCosts: undefined,
        recoverableCosts: {
          type: LegalCostsSwitchButtonType.percentage,
          value: 100,
        },
        incurredCosts: {
          type: LegalCostsSwitchButtonType.currency,
          value: 0,
        },
      }
  } else if (value === 'remove') {
    tempScenarioSnapshot.currentSnapshot.legalCosts.clientLegalCosts!.additionalProceedings =
      undefined
    tempScenarioSnapshot.currentSnapshot.legalCosts.opposingPartyLegalCosts!.additionalProceedings =
      undefined
  }

  return tempScenarioSnapshot
}

export function hasRecoverableLegalCosts(
  tempCurrentSnapshot: ScenarioSnapshot,
) {
  const legalCosts = tempCurrentSnapshot.legalCosts
  const recoverableFirstOwnIsZero =
    !legalCosts.clientLegalCosts.firstProceedings.recoverableCosts.value
  const recoverableFirstOtherIsZero =
    !legalCosts.opposingPartyLegalCosts.firstProceedings.recoverableCosts.value
  const recoverableSecondOwnIsZero =
    !legalCosts.clientLegalCosts.additionalProceedings ||
    !legalCosts.clientLegalCosts.additionalProceedings.recoverableCosts.value
  const recoverableSecondOtherIsZero =
    !legalCosts.opposingPartyLegalCosts.additionalProceedings ||
    !legalCosts.opposingPartyLegalCosts.additionalProceedings.recoverableCosts
      .value

  if (
    recoverableFirstOwnIsZero &&
    recoverableFirstOtherIsZero &&
    recoverableSecondOwnIsZero &&
    recoverableSecondOtherIsZero
  ) {
    return false
  }
  return true
}

export function getLegalCosts(
  legalCoststype: 'recoverableCosts' | 'incurredCosts' | 'estimatedCosts',
  party: LegalCostsParty,
  proceedings: LegalCostsProceedings,
  currentSnapshot: ScenarioSnapshot,
  userSettings: UserSettings,
  includeIncurredCosts?: boolean,
) {
  if (currentSnapshot.legalCosts.included === false) {
    return 0
  }
  if (legalCoststype === 'estimatedCosts') {
    return (
      currentSnapshot.legalCosts[party][proceedings]!.estimatedCosts! -
      includedIncurredCosts(
        party === LegalCostsParty.clientLegalCosts ? 'client' : 'opposing',
        proceedings === LegalCostsProceedings.firstProceedings
          ? 'first'
          : 'second',
        includeIncurredCosts ?? false,
        currentSnapshot,
      )
    )
  } else {
    const legalCostsSwitchButtonType: LegalCostsSwitchButtonType =
      currentSnapshot.legalCosts[party][proceedings]![legalCoststype].type

    if (legalCostsSwitchButtonType === LegalCostsSwitchButtonType.currency) {
      return formattedNumToString(
        currentSnapshot.legalCosts[party][proceedings]![legalCoststype]
          .value !== undefined
          ? roundNumberTo(
              currentSnapshot.legalCosts[party][proceedings]![legalCoststype]
                .value,
              3,
            )
          : 0,
        userSettings,
      )
    } else {
      return formattedNumToString(
        (currentSnapshot.legalCosts[party][proceedings]![legalCoststype]
          .value! /
          100) *
          currentSnapshot.legalCosts[party][proceedings]!.estimatedCosts!
          ? roundNumberTo(
              (currentSnapshot.legalCosts[party][proceedings]![legalCoststype]
                .value! /
                100) *
                currentSnapshot.legalCosts[party][proceedings]!.estimatedCosts!,
              3,
            )
          : 0,
        userSettings,
      )
    }
  }
}
