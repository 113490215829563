import React from 'react'
import tickedImg from '../../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../../resources/images/018-untickedGrayBlue.svg'
import { SectionTitleKeys } from '../../../../../../models/reportModels/reportEnums'
import { getText } from '../../../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { AvailableLanguages } from '../../../../../../models/enums'

type Props = {
  includeElement: boolean
  includeElementName: SectionTitleKeys
  handleIncludeExcludeElement: () => void
}

export default function IncludeOptionComponentForSections(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <div className="includeOptionContainer">
        <p
          className="includeOptionText"
          onClick={() => props.handleIncludeExcludeElement()}
          id={`includeOptionText-${props.includeElementName}`}
          data-textattribute="submenu-15"
          style={
            user.settings.language === AvailableLanguages.swedish
              ? { fontSize: '13.5px' }
              : {}
          }
        >
          {getText('submenu-15', user.settings)}
        </p>
        <img
          src={props.includeElement ? tickedImg : unTickedImg}
          alt="includeOptionImg"
          className="includeOptionImg"
          onClick={() => props.handleIncludeExcludeElement()}
        />
      </div>
    </>
  )
}
