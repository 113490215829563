import { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from '../states/UserState'
import { freemiumState } from '../states/FreemiumState'
import FreemiumUserPopUp from '../pages/userLoginWorkflow/FreemiumUserPopUp'
import UserPopup from '../pages/userLoginWorkflow/UserPopUp'
import { getAvailableFeatures, me } from '../services/requests'
import {
  deepCloneObject,
  findHandlingErrorState,
  isDevEnv,
  logActivity,
  updateFeaturesFromBackend,
} from '../services/commonFunctions'
import { handlingErrorsState } from '../states/HandlingErrorsState'
import { featuresState } from '../states/FeaturesState'
import { AvailableLanguages, SnapshotStatus } from '../models/enums'
import { Features, FeaturesFromBackend } from '../models/generalTypes'
import { User } from '../models/user'
import {
  getPreferencesFromStorage,
  syncAndGetUserid,
} from '../freemiumServices/freemiumServices'
import OpenReplay from '@openreplay/tracker'
import { getFreemium } from '../freemiumServices/freemiumRequests'
import { viewerState } from '../states/ViewerState'
import { recoveryModeState } from '../states/RecoveryModeState'
import { scenarioIdentityState } from '../states/ScenarioIdentityState'
import { ProductFruits } from 'react-product-fruits'
import { krogerusColorsState } from '../states/KrogerusColorsState'
import { checkInWithMicrosoft } from '../services/sso_functions'
import { onlineState } from '../states/OnlineState'

// If you want to enable it locally allow the isDevEnv() and add __DISABLE_SECURE_MODE: true,
const tracker = !isDevEnv()
  ? new OpenReplay({
      projectKey: process.env.REACT_APP_PROJECT_KEY ?? '',
      ingestPoint: process.env.REACT_APP_PROJECT_KEY_INGEST_POINT,
      obscureTextEmails: true,
      obscureTextNumbers: false,
      obscureInputEmails: true,
      obscureInputDates: true,
      defaultInputMode: 1,
      respectDoNotTrack: true,
      //__DISABLE_SECURE_MODE: true,
    })
  : undefined

type Props = {
  children: JSX.Element
  setFirstTimeLogin: (value: boolean) => void
}

const UserInitHOC = (props: Props) => {
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingFeatures, setLoadingFeatures] = useState(true)
  const online = useRecoilValue(onlineState)
  const [user, setUser] = useRecoilState(userState)
  const setKrogerusColors = useRecoilState(krogerusColorsState)[1]
  const [freemium, setFreemium] = useRecoilState(freemiumState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [features, setFeatures] = useRecoilState(featuresState)
  const hasInitialized = useRef(false)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const setRecoveryMode = useSetRecoilState(recoveryModeState)
  const setScenarioIdentity = useSetRecoilState(scenarioIdentityState)
  const [userForProductFruits, setUserForProductFruits] = useState<
    undefined | any
  >(undefined)
  const [isProductFruitAllowed, setIsProductFruitAllowed] = useState(false)

  const userInitialization = () => {
    let tempFeatures = deepCloneObject(features) as Features
    getAvailableFeatures().then((featuresRes) => {
      setHandlingErrors(
        findHandlingErrorState(
          featuresRes,
          handlingErrors,
          'getAvailableFeatures',
          true,
        ),
      )
      if (!('errorCode' in featuresRes)) {
        let featuresFromBackend = featuresRes.data as FeaturesFromBackend
        tempFeatures = updateFeaturesFromBackend(
          tempFeatures,
          featuresFromBackend,
        )
        if (tempFeatures.use_recordings && tracker) {
          tracker.start()
        }
        setFeatures(tempFeatures)
        setLoadingFeatures(false)
      }
    })

    me().then((res) => {
      if (!('errorCode' in res)) {
        const newUser = User.UserFromDB(res.data)

        if (tempFeatures.use_translation === false) {
          newUser.settings.language = AvailableLanguages.english
        }
        setUser(newUser)
        if (
          // window.location.hostname.includes('localhost') ||
          window.location.hostname.includes('krogerus.eperoto.com') ||
          window.location.hostname.includes('counterstrike.eperoto.com')
        ) {
          setKrogerusColors(true)
        } else {
          setKrogerusColors(false)
        }
        if (
          window.location.hostname.includes('test') ||
          window.location.hostname.includes('counterstrike') ||
          window.location.hostname.includes('redalert')
        ) {
          setIsProductFruitAllowed(true)
          setUserForProductFruits({
            username: newUser.email, // REQUIRED - any unique user identifier
            email: newUser.email,
            firstname: newUser.firstName,
            lastname: newUser.lastName,
            signUpAt: newUser.registeredDate,
            role: newUser.role,
          })
        }
        setTrackerInfo(newUser)
        //Mixpanel 1
        logActivity(false, 'Logged in')
      } else {
        setUser(User.EmptyUser())
      }
      setLoadingUser(false)
    })
  }

  const freemiumUserInitialization = async (isLoggedIn: boolean) => {
    const newUser = User.EmptyUserFreemium()

    if (isLoggedIn) {
      newUser.email = 'user'
      newUser.token = syncAndGetUserid() ?? ''

      const userPrefs = getPreferencesFromStorage()
      newUser.checklist = userPrefs.checklist
      newUser.settings = userPrefs.settings

      setTrackerInfo(newUser, true)
      //Mixpanel 1 (Freemium)
      logActivity(true, 'Logged in')
    }

    setFreemium({ isFreemium: true, showMessage: undefined })
    setUser(newUser)
    setLoadingUser(false)
    setFeatures({
      use_logs: true,
      use_recordings: true,
      use_statistical_model: false,
      use_sms: false,
      use_whitelabel: false,
      share_case: false,
      use_microsoft_sso: false,
      use_translation: false,
      available_languages: [AvailableLanguages.english],
    })

    if (tracker) {
      tracker.start()
    }
  }

  const setTrackerInfo = (newUser: User, forFreemium?: boolean) => {
    if (tracker) {
      tracker.setUserID(forFreemium ? newUser.token : newUser.fullName())
      tracker.setMetadata('Company', newUser.companyName)
      tracker.setMetadata('Email', newUser.email)
    }
  }

  useEffect(() => {
    if (!hasInitialized.current) {
      if (
        window.location.href.includes('/microsoft_login/') &&
        !window.location.href.includes('/#/')
      ) {
        window.location.href = window.location.href.replace(
          '/microsoft_login/',
          '/#/microsoft_login/',
        )
      }

      getFreemium().then((freemiumRes) => {
        if (!('errorCode' in freemiumRes)) {
          freemiumUserInitialization(freemiumRes.data.logged_in)
        } else if (freemiumRes.errorCode === 404) {
          userInitialization()
        } else {
          setHandlingErrors(
            findHandlingErrorState(
              freemiumRes,
              handlingErrors,
              'getFreemium',
              true,
            ),
          )
        }
      })

      hasInitialized.current = true
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    const startMicrosoftCheckIn = () => {
      if (
        features.use_microsoft_sso &&
        user.isLoggedIn() &&
        user.email !== undefined
      ) {
        checkInWithMicrosoft(user.email).then((res) => {
          if (res === false) {
            setUser(User.EmptyUser())
          }
        })

        timer = setInterval(() => {
          if (online.networkOn === true && user.email !== undefined) {
            checkInWithMicrosoft(user.email).then((res) => {
              if (res === false) {
                setUser(User.EmptyUser())
              }
            })
          }
        }, 1000 * 60 * 10) // 10 minutes
      }
    }

    const stopMicrosoftCheckIn = () => {
      if (timer) {
        console.log('cleared interval checkInWithMicrosoft')
        clearInterval(timer)
        timer = null
      }
    }

    if (online.networkOn === true && user.isLoggedIn()) {
      startMicrosoftCheckIn()
    } else {
      stopMicrosoftCheckIn()
    }

    // Cleanup function to stop the interval when the component unmounts or dependencies change
    return () => {
      stopMicrosoftCheckIn()
    }

    // eslint-disable-next-line
  }, [features, user, online.networkOn])

  useEffect(() => {
    setIsResetPassword(
      window.location.href.includes('/reset_password/') ||
        window.location.href.includes('/microsoft_login/'),
    )
    setIsViewer({ ...isViewer, isViewer: false })
    setRecoveryMode({
      recoveryPreview: 'none',
      shaking: false,
      showLowScreenMessage: false,
      keepResults: false,
      old: undefined,
      new: undefined,
    })

    if (user.email === '') {
      props.setFirstTimeLogin(false)

      setScenarioIdentity({
        caseId: '',
        scenarioId: '',
        snapshotId: '',
        caseName: '',
        scenarioName: '',
        snapshotStatus: SnapshotStatus.None,
        snapshotProgress: 0,
        results: undefined,
        hasReport: false,
      })
    }

    // eslint-disable-next-line
  }, [user])

  return (
    <>
      {loadingUser ? (
        <div className="basicBlueBackground"></div>
      ) : !user.isLoggedIn() && !isResetPassword ? (
        freemium.isFreemium ? (
          <FreemiumUserPopUp
            setTrackerInfo={setTrackerInfo}
            setAsFirstTimeLogin={() => props.setFirstTimeLogin(true)}
          />
        ) : (
          <UserPopup
            setTrackerInfo={setTrackerInfo}
            setAsFirstTimeLogin={() => props.setFirstTimeLogin(true)}
            loadingFeatures={loadingFeatures}
          />
        )
      ) : (
        props.children
      )}
      {isProductFruitAllowed && (
        <ProductFruits
          workspaceCode="RHnfP5g6W6bbW7hj"
          language="en"
          user={
            userForProductFruits ?? {
              username: 'no user', // REQUIRED - any unique user identifier
              email: '',
              firstname: '',
              lastname: '',
              signUpAt: '',
              role: '',
            }
          }
        />
      )}
    </>
  )
}

export default UserInitHOC
