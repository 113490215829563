import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  AccessRole,
  AvailableLanguages,
  SettingsTabs,
  SnapshotStatus,
  ToolTabs,
  TutorialTabs,
} from '../../models/enums'
import { getText } from '../../services/textFunctions'
import { activeTabsState } from '../../states/ActiveTabsState'
import { onlineState } from '../../states/OnlineState'
import { userState } from '../../states/UserState'
import { AnimationDots } from '../Animations/AnimationDots'
import { ANIMATION_DOTS_SPEED } from '../../services/constants'
import { recoveryModeState } from '../../states/RecoveryModeState'
import useWindowSize from '../../customHooks/useWindowSize'

type Props = {
  type: 'ToolTabs' | 'TutorialTabs' | 'SettingsTabs'
  name: ToolTabs | TutorialTabs | SettingsTabs
  isActive: boolean
  setActiveTab: (param: any) => void
  snapshotStatus?: SnapshotStatus
  setOpenTutorial?: (param: string | undefined) => void
  inactiveTabs?: boolean
  errors?: number
  ownRole?: AccessRole
}

const SingleTab = (props: Props) => {
  const user = useRecoilValue(userState)
  const recoveryMode = useRecoilValue(recoveryModeState)
  const [online, setOnline] = useRecoilState(onlineState)
  const { width } = useWindowSize()
  //Tool Tab specifics/////////////////////////////

  const activeToolTabs = useRecoilValue(activeTabsState)

  const isAnalyzingCase =
    props.name === ToolTabs.results &&
    (props.snapshotStatus === SnapshotStatus.CalculatingTrees ||
      props.snapshotStatus === SnapshotStatus.Saving ||
      props.snapshotStatus === SnapshotStatus.Queuing ||
      props.snapshotStatus === SnapshotStatus.Queued ||
      props.snapshotStatus === SnapshotStatus.Processing ||
      props.snapshotStatus === SnapshotStatus.ProcessingReversed)

  const doesCaseHaveResults =
    props.name === ToolTabs.results &&
    props.snapshotStatus === SnapshotStatus.Done &&
    (recoveryMode.recoveryPreview !== 'new' || recoveryMode.keepResults)

  const isCaseFailed =
    props.name === ToolTabs.results &&
    props.snapshotStatus === SnapshotStatus.Failed

  const isAnalyzeButtonOn = props.name === ToolTabs.results

  ////////////////////////////////////////////////

  useEffect(() => {
    if (user.settings.language === AvailableLanguages.swedish && width < 950) {
      const elements = document.querySelector('.tabName')

      if (elements) {
        const elementsArray = Array.from(elements.children)
        elementsArray.forEach((element: any) => {
          element.style.setProperty('fontSize', '12px', 'important')
        })
      }
    }

    // eslint-disable-next-line
  }, [width])

  const onTabClick = () => {
    switch (props.type) {
      case 'ToolTabs':
        if (
          props.inactiveTabs ||
          (props.name === ToolTabs.results &&
            props.snapshotStatus !== SnapshotStatus.Done &&
            props.snapshotStatus !== SnapshotStatus.Failed)
        ) {
          return undefined
        } else {
          props.setActiveTab({ tab: props.name, claim: activeToolTabs.claim })
        }
        break
      case 'TutorialTabs':
      case 'SettingsTabs':
        if (online.networkOn) {
          if (props.type === 'TutorialTabs') {
            props.setOpenTutorial!(undefined)
          }
          props.setActiveTab(props.name)
        } else {
          setOnline({ ...online, shaking: true })
        }
        break
    }
  }

  return (
    <div
      className={
        props.inactiveTabs
          ? 'singleTabContainer inactive'
          : props.type !== 'ToolTabs'
          ? 'singleTabContainer singleTutorialTabContainer'
          : 'singleTabContainer'
      }
      onClick={onTabClick}
    >
      {isAnalyzingCase ? (
        <div className="analyzing">
          <div
            className="tabName tabNameActive"
            style={{ cursor: 'default' }}
            data-textattribute="title-14"
            id="tabName-analyzing"
          >
            {getText('title-14', user.settings)}
            <AnimationDots speed={ANIMATION_DOTS_SPEED} />
          </div>
        </div>
      ) : doesCaseHaveResults ? (
        <p
          className={props.isActive ? 'tabName tabNameActive' : 'tabName'}
          id="tabName-results"
          data-textattribute="title-20"
        >
          {getText('title-20', user.settings)}
        </p>
      ) : isCaseFailed ? (
        <button type="button" className="analyseCaseButton failedCaseButton">
          <p
            className="analyseCaseButtonText failedCaseButtonText"
            id="failedCaseButtonText"
            data-textatrtibute="button-45"
          >
            {getText('button-45', user.settings)}
          </p>
        </button>
      ) : isAnalyzeButtonOn ? (
        <button
          type={props.ownRole === AccessRole.VIEWER ? 'button' : 'submit'}
          className="analyseCaseButton"
          id="analyseCaseButton"
          disabled={props.ownRole === AccessRole.VIEWER}
        >
          <p
            className={`analyseCaseButtonText ${
              props.ownRole === AccessRole.VIEWER ? 'disabled' : ''
            }`}
            id="analyseCaseButtonText"
            data-textattribute="button-46"
          >
            {getText('button-46', user.settings)}
          </p>
        </button>
      ) : (
        <div
          className={
            props.inactiveTabs
              ? 'tabName inactive'
              : props.isActive
              ? props.type !== 'ToolTabs'
                ? 'tabName tabNameActive tutorialsTabName'
                : 'tabName tabNameActive'
              : props.type !== 'ToolTabs'
              ? 'tabName tutorialsTabName'
              : 'tabName'
          }
          id={
            props.name === ToolTabs.proceedings
              ? 'tabName-proceedings'
              : props.name === ToolTabs.claims
              ? 'tabName-claims'
              : props.name === ToolTabs.legalCosts
              ? 'tabName-legalCosts'
              : props.name === ToolTabs.legalCostsDistribution
              ? 'tabName-legalCostsDistribution'
              : props.name === ToolTabs.advanced
              ? 'tabName-advanced'
              : props.name === TutorialTabs.Tutorials
              ? 'tabName-academy'
              : props.name === TutorialTabs.UserManual
              ? 'tabName-userManual'
              : props.name === SettingsTabs.AccountDetails
              ? 'tabName-accountDetails'
              : props.name === SettingsTabs.Preferences
              ? 'tabName-preferences'
              : props.name === SettingsTabs.Subscription
              ? 'tabName-subscription'
              : props.name === SettingsTabs.Users
              ? 'tabName-users'
              : undefined
          }
          data-textattribute={
            props.name === ToolTabs.proceedings
              ? 'title-15'
              : props.name === ToolTabs.claims
              ? 'title-16'
              : props.name === ToolTabs.legalCosts
              ? 'title-17'
              : props.name === ToolTabs.legalCostsDistribution
              ? 'title-18'
              : props.name === ToolTabs.advanced
              ? 'title-00'
              : props.name === TutorialTabs.Tutorials
              ? 'title-21'
              : props.name === TutorialTabs.UserManual
              ? 'title-22'
              : props.name === SettingsTabs.AccountDetails
              ? 'title-163'
              : props.name === SettingsTabs.Preferences
              ? 'title-164'
              : props.name === SettingsTabs.Subscription
              ? 'title-165'
              : props.name === SettingsTabs.Users
              ? 'title-166'
              : null
          }
        >
          {props.name === ToolTabs.proceedings
            ? getText('title-15', user.settings)
            : props.name === ToolTabs.claims
            ? getText('title-16', user.settings)
            : props.name === ToolTabs.legalCosts
            ? getText('title-17', user.settings)
            : props.name === ToolTabs.legalCostsDistribution
            ? getText('title-18', user.settings)
            : props.name === ToolTabs.advanced
            ? getText('title-242', user.settings)
            : props.name === TutorialTabs.Tutorials
            ? getText('title-21', user.settings)
            : props.name === TutorialTabs.UserManual
            ? getText('title-22', user.settings)
            : props.name === SettingsTabs.AccountDetails
            ? getText('title-163', user.settings)
            : props.name === SettingsTabs.Preferences
            ? getText('title-164', user.settings)
            : props.name === SettingsTabs.Subscription
            ? getText('title-165', user.settings)
            : props.name === SettingsTabs.Users
            ? getText('title-166', user.settings)
            : null}
          {props.errors !== undefined && props.errors > 0 && (
            <div className="errors">{props.errors}</div>
          )}
        </div>
      )}

      <div
        className={
          props.inactiveTabs
            ? 'tabName inactive'
            : props.isActive &&
              (props.name !== ToolTabs.results ||
                props.snapshotStatus === SnapshotStatus.Done)
            ? 'tabUnderline tabUnderlineActive'
            : 'tabUnderline'
        }
      ></div>
    </div>
  )
}

export default SingleTab
