import { useState, useEffect } from 'react'

import InfoClickComponent from '../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'

import tickedImg from '../../../../../resources/images/115-tickPurple.svg'
import greyTickedImg from '../../../../../resources/images/260-tickGrey.svg'
import unTickedImg from '../../../../../resources/images/018-untickedGrayBlue.svg'
import bankruptcyImg from '../../../../../resources/images/283-bankruptcySymbol.svg'
import opposingPartySymbolImg from '../../../../../resources/images/280-opposingPartySymbol.svg'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../services/commonFunctions'
import { SnapshotSelectorObject } from '../../../../../models/generalTypes'
import {
  AccessRole,
  AvailableLanguages,
  TypeOfInstance,
  UndoRedoType,
  VideoPostName,
} from '../../../../../models/enums'
import { changeGlobalSnapshot } from '../../../../../services/changeGlobalSnapshot'
import InputNum from '../../../../../Components/Inputs/InputNum/InputNum'
import { userState } from '../../../../../states/UserState'
import { getSplitText, getText } from '../../../../../services/textFunctions'
import VideoInfoButton from '../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { viewerState } from '../../../../../states/ViewerState'
import { recoveryModeState } from '../../../../../states/RecoveryModeState'
import { ScenarioSnapshot } from '../../../../../models/scenarioSnapshot'
import { freemiumState } from '../../../../../states/FreemiumState'
import PremiumFeatureIcon from '../../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../../../models/freemiumEnums'

type Props = {
  setVideoPostName: (param: VideoPostName | undefined) => void
  errors: string[]
  ownRole: AccessRole
  handleChangeKeyValue: (
    e: any,
    key: keyof ScenarioSnapshot,
    number?: boolean,
  ) => void
}

export default function Enforcement(props: Props) {
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const currentSnapshot = scenarioSnapshot.currentSnapshot

  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str

  const user = useRecoilValue(userState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  function handleToggleIncludeEnforcement() {
    if (props.ownRole === AccessRole.VIEWER) {
      if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
      }
      return
    }

    if (freemium.isFreemium && !currentSnapshot.hasBankruptcy) {
      setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
      return
    }

    const tempValue = !currentSnapshot.hasBankruptcy
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `bankruptcyAddRemoveButton`,
      undoRedoType: UndoRedoType.button,
      value: tempValue,
      key: 'hasBankruptcy',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    if (!tempValue) {
      tempScenarioSnapshot.currentSnapshot.bankruptcyPercentage = undefined
    } else {
      setTimeout(() => {
        ;(
          document.getElementById('bankruptcyPercentage') as HTMLInputElement
        ).select()
      }, 100)
    }
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    if (tempValue === true) {
      //Mixpanel 31
      logActivity(false, 'Added enforcement submenu')
    } else {
      //Mixpanel 32
      logActivity(false, 'Removed enforcement submenu')
    }
  }

  return (
    <>
      <div
        className="advancedTabSubTitleContainer"
        id={`enforcementTitle`}
        data-textattribute="title-36"
      >
        <p id="enforcementTitleText">{getText('title-36', user.settings)}</p>
        <img
          src={bankruptcyImg}
          alt="bankruptcyImg"
          className="bankruptcyImg"
        />

        <div>
          <VideoInfoButton
            textAttribute="tooltip-19"
            postName={VideoPostName.enforcement}
            setVideoPostName={props.setVideoPostName}
          />
        </div>
      </div>
      <div
        id="bankruptcyAddRemoveButtonContainer"
        className="tool-component-add-button-container marginLeft1 subtitleTickboxContainer"
      >
        {currentSnapshot.hasBankruptcy === true ? (
          props.ownRole === AccessRole.VIEWER ? (
            <img
              src={greyTickedImg}
              alt="tickedGreyImg"
              className="tickImg disabled ticked"
              id="bankruptcyAddRemoveButton"
              tabIndex={30000}
              onClick={handleToggleIncludeEnforcement}
            />
          ) : (
            <img
              src={tickedImg}
              alt="tickedImg"
              className="tickImg ticked"
              id="bankruptcyAddRemoveButton"
              tabIndex={30000}
              onClick={handleToggleIncludeEnforcement}
            />
          )
        ) : (
          <img
            src={unTickedImg}
            alt="unTickedImg"
            className={
              props.ownRole === AccessRole.VIEWER
                ? 'tickImg disabled unticked'
                : 'tickImg unticked'
            }
            id="bankruptcyAddRemoveButton"
            tabIndex={30000}
            onClick={handleToggleIncludeEnforcement}
          />
        )}
        <p
          onClick={handleToggleIncludeEnforcement}
          id="bankruptcyAddRemoveText"
          className={props.ownRole === AccessRole.VIEWER ? 'disabled' : ''}
          data-textattribute="submenu-13"
        >
          {getText('submenu-13', user.settings)}
        </p>
        <img
          src={opposingPartySymbolImg}
          alt="opposingPartySymbolImg"
          className="opposingPartySymbolImg"
        />
      </div>
      {freemium.isFreemium && !currentSnapshot.hasBankruptcy && (
        <PremiumFeatureIcon
          targetId={'bankruptcyAddRemoveButtonContainer'}
          forEnforcement
        />
      )}
      {currentSnapshot.hasBankruptcy === true ? (
        <>
          <p
            className="tool-component-subtitle marginLeft3"
            style={{ marginTop: '32px', maxWidth: '1080px' }}
            id="enforcement-description"
            data-textattribute="description-42"
          >
            {getText('description-42', user.settings)}
          </p>
          <div
            className="tool-component-input-container marginLeft3 first-container"
            style={{ marginTop: '47px' }}
          >
            <InputNum
              label={
                user.settings.language === AvailableLanguages.english
                  ? getSplitText(
                      getText('label-42', user.settings, partiesFormat),
                      '’s',
                      64,
                      14,
                    )
                  : getText('label-42', user.settings, partiesFormat)
              }
              labelTextAttribute="label-42"
              suffix="%"
              value={currentSnapshot.bankruptcyPercentage}
              className="form-control robotoNumbers"
              maxValue={100}
              maxNumberOfDecimals={0}
              tabIndex={30001}
              name={'bankruptcyPercentage'}
              onChange={(e: any) =>
                props.handleChangeKeyValue(e, 'bankruptcyPercentage')
              }
              id="bankruptcyPercentage"
              width={430}
              placeholderPadding="50px"
              error={props.errors.includes('bankruptcyPercentage')}
              errorMessage={getText('error-30', user.settings)}
              errorTextAttribute={'error-30'}
              readOnly={
                props.ownRole === AccessRole.VIEWER || freemium.isFreemium
              }
              disabled={
                props.ownRole === AccessRole.VIEWER || freemium.isFreemium
              }
            />
            <InfoClickComponent
              infoClickText={`${
                currentSnapshot.court === TypeOfInstance.Public_Court
                  ? getText('infoButton-35', user.settings, partiesFormat)
                  : currentSnapshot.court === TypeOfInstance.Arbitration
                  ? getText('infoButton-36', user.settings, partiesFormat)
                  : getText('infoButton-37', user.settings, partiesFormat)
              }\\n${getText('infoButton-38', user.settings, partiesFormat)}
                 `}
              infoClickTextAttribute={
                currentSnapshot.court === TypeOfInstance.Public_Court
                  ? 'infoButton-35, infoButton-38'
                  : currentSnapshot.court === TypeOfInstance.Arbitration
                  ? 'infoButton-36, infoButton-38'
                  : 'infoButton-37, infoButton-38'
              }
              idName="bankruptcyPercentage"
              hoverElement={hoverElement}
              setHoverElement={setHoverElement}
              width="300px"
            />
          </div>
        </>
      ) : null}
    </>
  )
}
