import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { getText } from '../../../../../../../../../services/textFunctions'
import {
  AvailableLanguages,
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../../models/enums'
import { User } from '../../../../../../../../../models/user'
import { ScenarioSnapshot } from '../../../../../../../../../models/scenarioSnapshot'
import {
  getStyleFromWhitelabel,
  legalCostFontSizeForPDF,
} from '../../../../../../../../../services/reportFunctions'
import {
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { getLegalCosts } from '../../../../../../../../../services/legalCostsFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'
import { ResultsSettings } from '../../../../../../../../../models/generalTypes'
import { getLegalCostsSingleValue } from '../../../../../../../../../services/resultsFunctions'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  party: LegalCostsParty
  user: User
  currentSnapshot: ScenarioSnapshot
  proceedings: LegalCostsProceedings
  legalCostsDescriptions?: ReportLegalCostsType
  reportWhitelabel?: ReportWhitelabel
  resultsSettings: ResultsSettings
}

export default function LegalCostsExecutiveSummary3Document(props: Props) {
  const styles = StyleSheet.create({
    legalCostsComponent: {
      marginTop: 3,
    },
    costsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    legalCostsTitle: {
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontSize:
        props.user.settings.language !== AvailableLanguages.english
          ? '7px'
          : '8px',
      marginLeft: '6px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).marginTop,
    },
    mediumText: {
      marginLeft: '0px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
    },
    mediumValue: {
      marginLeft: '0px',
      fontFamily: 'Roboto-Medium',
    },
    legalCostsValue: {
      fontSize: '8px',
      fontFamily: 'Roboto-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    span: {
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
  })
  const hasSecondTrial = props.currentSnapshot.probSecondTrial! > 0
  const includeIncurredCosts =
    props.resultsSettings.settings.includeIncurredCosts

  const firstProceedingsDefaultMessage = hasSecondTrial
    ? includeIncurredCosts === false
      ? //title-281
        getText('title-281', props.user.settings)
      : getText('title-129', props.user.settings)
    : includeIncurredCosts === false
    ? //title-282
      getText('title-282', props.user.settings)
    : getText('title-130', props.user.settings)

  const secondProceedingsDefaultMessage =
    props.currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        includeIncurredCosts === false
        ? //title-283
          getText('title-283', props.user.settings)
        : getText('title-131a', props.user.settings)
      : //title-131b
      includeIncurredCosts === false
      ? //title-284
        getText('title-284', props.user.settings)
      : getText('title-131b', props.user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  return (
    <>
      <View style={styles.legalCostsComponent}>
        <View style={styles.costsContainer}>
          <Text style={{ ...styles.legalCostsTitle, ...styles.mediumText }}>
            {props.proceedings === LegalCostsProceedings.additionalProceedings
              ? secondProceedingsText.length > 0
                ? secondProceedingsText
                : secondProceedingsDefaultMessage
              : firstProceedingsText.length > 0
              ? firstProceedingsText
              : firstProceedingsDefaultMessage}
            :
          </Text>
          <Text
            style={{
              ...styles.legalCostsValue,
              ...styles.mediumValue,
              ...legalCostFontSizeForPDF(
                getLegalCostsSingleValue(
                  getLegalCosts(
                    'estimatedCosts',
                    props.party,
                    props.proceedings,
                    props.currentSnapshot,
                    props.user.settings,
                    includeIncurredCosts,
                  ),
                  props.currentSnapshot.currency,
                  props.user.settings,
                ),
              ),
            }}
          >
            {getLegalCostsSingleValue(
              getLegalCosts(
                'estimatedCosts',
                props.party,
                props.proceedings,
                props.currentSnapshot,
                props.user.settings,
                includeIncurredCosts,
              ),
              props.currentSnapshot.currency,
              props.user.settings,
            )}
          </Text>
        </View>
        <View style={styles.costsContainer}>
          <Text style={styles.legalCostsTitle}>
            {getText('label-89', props.user.settings)}:
          </Text>
          <Text style={styles.legalCostsValue}>
            {getLegalCostsSingleValue(
              getLegalCosts(
                'recoverableCosts',
                props.party,
                props.proceedings,
                props.currentSnapshot,
                props.user.settings,
              ),
              props.currentSnapshot.currency,
              props.user.settings,
            ).replace(props.currentSnapshot.currency, '')}
            <Text style={styles.span}> {props.currentSnapshot.currency}</Text>
          </Text>
        </View>
        {includeIncurredCosts && (
          <View style={styles.costsContainer}>
            <Text style={styles.legalCostsTitle}>
              {getText('label-91', props.user.settings)}:
            </Text>
            <Text style={styles.legalCostsValue}>
              {getLegalCostsSingleValue(
                getLegalCosts(
                  'incurredCosts',
                  props.party,
                  props.proceedings,
                  props.currentSnapshot,
                  props.user.settings,
                ),
                props.currentSnapshot.currency,
                props.user.settings,
              ).replace(props.currentSnapshot.currency, '')}
              <Text style={styles.span}> {props.currentSnapshot.currency}</Text>
            </Text>
          </View>
        )}
      </View>
    </>
  )
}
