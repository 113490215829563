import React from 'react'
import tickedImg from '../../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../../resources/images/018-untickedGrayBlue.svg'
import { ExecutiveSummaryVisibleElementsEnum } from '../../../../../../models/reportModels/reportEnums'
import { getText } from '../../../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { AvailableLanguages } from '../../../../../../models/enums'

type Props = {
  includeElement: boolean
  includeElementName: ExecutiveSummaryVisibleElementsEnum
  handleIncludeExcludeElement: (
    param: ExecutiveSummaryVisibleElementsEnum,
  ) => void
}

export default function IncludeOptionComponent(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <div
        className="includeOptionContainer"
        id={`includeOptionContainer-${props.includeElementName}`}
      >
        <p
          className="includeOptionText"
          onClick={() =>
            props.handleIncludeExcludeElement(props.includeElementName)
          }
          id={`includeOptionText-${props.includeElementName}`}
          data-textattribute="submenu-15"
          style={
            user.settings.language === AvailableLanguages.swedish
              ? { fontSize: '14px' }
              : {}
          }
        >
          {getText('submenu-15', user.settings)}
        </p>
        <img
          src={props.includeElement ? tickedImg : unTickedImg}
          alt="includeOptionImg"
          className="includeOptionImg"
          onClick={() =>
            props.handleIncludeExcludeElement(props.includeElementName)
          }
        />
      </div>
    </>
  )
}
